import React, { useEffect, useState } from 'react';
import logo from '../../assets/images/noveline_logo_small.png'
import './HeaderStyle.scss';
import { useNavigate } from "react-router-dom";


const Header = ({ activeName }) => {
    const navigate = useNavigate();
    const [solActive, setSolActive] = useState('nav-link');
    const [teamActive, setTeamActive] = useState('nav-link');
    const [caseStudyActive, setcaseStudyActive] = useState('nav-link');
    useEffect(() => {
        if (activeName.includes('/solution'))
            setSolActive('nev-link-Active')
        else if (activeName.includes('/CaseStudy'))
            setcaseStudyActive('nev-link-Active')
        else if (activeName.includes('/mainteam'))
            setTeamActive('nev-link-Active')
    }, [activeName]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <nav className="navbar fixed-top navbar-expand-lg navbar_bg">
                <a className="navbar-brand" href="/">
                    <img src={logo} alt="NovelineLogo" width="200" height="42"
                        className="d-inline-block align-text-top" />
                </a>
                <button className="navbar-toggler border" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon fa fa-bars"></span>
                </button>
                <div className='container'>
                    <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0 ms-5 topMenuText">
                            <li className="nav-item">
                                <label className={`nav-link ${solActive}`} aria-current="page" onClick={() => navigate("/solution")}>Solutions</label>

                            </li>
                            <li className="nav-item text-white">
                                <label className={`nav-link ${caseStudyActive} ms-3`} onClick={() => navigate("/CaseStudy")}>Case Studies</label>
                            </li>
                            <li className="nav-item">
                                <label className={`nav-link ${teamActive} ms-3`} onClick={() => navigate("/mainteam")}>Team</label>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Header;