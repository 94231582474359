import React from 'react'
import Header from '../modules/HeaderModule/Header'
import Footer from '../modules/FooterModule/Footer'
import HelpDeskSolutionPage from '../modules/SolutionsPagesModule/HelpDeskSolutionPage'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const HelpPage = () => {
  const location = useLocation();

  useEffect(() => {
    document.title = 'Noveline - HelpDesk';
  }, []);

  return (
    <>
      <Header activeName={location.pathname} />
      <HelpDeskSolutionPage />
      <Footer scroll_position={100} />
    </>
  )
}

export default HelpPage