import { React } from 'react';
import Header from '../modules/HeaderModule/Header'
import Footer from '../modules/FooterModule/Footer'
import BrassModule from '../modules/BrassModule/BrassModule'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const BrassPage = () => {
  const location = useLocation();

  useEffect(() => {
    document.title = 'Noveline - BRASS';
  }, []);

  return (
    <>
      <Header activeName={location.pathname} />
      <BrassModule />
      <Footer scroll_position={100} />
    </>
  )
}

export default BrassPage