import React from 'react';
import './FooterStyle.scss';
import Office from '../../assets/images/OfficeIcon.png';
import LocationIcon from '../../assets/images/location_icon.png';

import Carousel from '../Carousel/carousel';
import { useState, useEffect } from 'react';

const Footer = ({ scroll_position }) => {
    const [initiateSlide, setInitiateSlide] = useState("slide-track1");

    const listenScrollEvent = (event) => {
        if (window.scrollY < scroll_position) {
            return setInitiateSlide("slide-track1")
        } else if (window.scrollY >= scroll_position) {
            return setInitiateSlide("slide-track")
        }
    }

    useEffect(() => {
        if (scroll_position === 0)
            setInitiateSlide("slide-track");
        else {
            window.addEventListener('scroll', listenScrollEvent);

            return () =>
                window.removeEventListener('scroll', listenScrollEvent);
        }
    }, []);

    return (
        <>
            <div className="container-fluid footer mt-4">
                <div className='row'>
                    <div className='col-12 col-lg-2 mt-4 p-4 pb-0 text-center'>
                        <img src={Office} className="img-fluid" alt="Marketing Office" />
                    </div>
                    <div className='col-12 col-lg-10 mt-4 pb-0'>
                        <div className="container mt-4 text-center">
                            <span className='clientHeading'>CLIENTS</span>
                        </div>
                        <div className="container-fluid ps-5 pt-3 mainContainerFluid">
                            <div>
                                <Carousel initiate_slide={initiateSlide} />
                            </div>
                            <span className='footer-note'>** All product names, logos and brands are property of their respective owners</span>
                        </div>
                    </div>
                </div>
                <div className='p-4'>
                    <div className='row justify-content-between'>
                        <div className='col-12 col-md-4 border-hide'>
                            <span className='mainHeadingFooter'>Marketing Office</span><br></br>
                            <div className='d-flex align-items-center'>
                                <span className='mainHeadingSubFooter'>Thane</span>
                                <a href='https://goo.gl/maps/ZsWRwqvoHE863rh67' style={{ cursor: 'pointer' }} target={'_blank'}><img src={LocationIcon} className="img-fluid ml-1" alt="Location" /></a>
                            </div>
                            <div className='address'>118, Tiffany Building,
                                Hiranandani Road, Hiranandani Estate,
                                Thane (West) - 400607.
                                Maharashtra. </div>
                            <div className='mt-2'>
                                <span className='text-white fa fa-solid fa-envelope'></span>
                                <span className='address mx-2'><a href="mailto:contact@noveline.co.in">contact@noveline.co.in</a></span>
                            </div>
                            <div className='mt-2'>
                                <span className='text-white fa fa-solid fa-phone'></span>
                                <span className='address mx-2'><a href="tel:02268181621">+91-022-6818-1621</a></span>
                            </div>
                        </div>
                        <div className='col-12 border-up px-4 col-md-4'>
                            &nbsp;
                        </div>
                        <div className='col-12 mt-3 border-up  px-4 col-md-4 mx-sm-auto my-md-0 '>
                            <div >
                                <span className='mainHeadingFooter'>Development Office</span><br></br>
                                <div className='d-flex align-items-center'>
                                    <span className='mainHeadingSubFooter'>Nagpur</span>
                                    <a href="https://goo.gl/maps/ZutkZBHw1G5nx5Zh9" style={{ cursor: 'pointer' }} target={'_blank'}><img src={LocationIcon} className="img-fluid ml-1" alt="Location" /></a>
                                </div>
                                <div className='address'>Kasturba Bhavan, Abhyankar Nagar Road, Bajaj Nagar, Nagpur - 440010.
                                    Maharashtra.  </div>
                                <div className='mt-2'>
                                    <span className='text-white fa fa-solid fa-envelope'></span>
                                    <span className='address mx-2'><a href="mailto:contact@noveline.co.in">contact@noveline.co.in</a></span>
                                </div>
                                <div className='mt-2'>
                                    <span className='text-white fa fa-solid fa-phone'></span>
                                    <span className='address mx-2'><a href="tel:02268181621">+91-022-6818-1621</a></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {/* -------scroll up-------- */}
            {/* <div class="container-top">
                <a href="#" class="top"></a>
            </div> */}
        </>
    )
}

export default Footer;