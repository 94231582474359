import React from 'react'
import Header from '../modules/HeaderModule/Header'
import TeaBlendModule from '../modules/TeaBlendModule/TeaBlendModule'
import Footer from '../modules/FooterModule/Footer'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
// import '.'
const TeaBlendPage = () => {
  const location = useLocation();
  
  useEffect(() => {
    document.title = 'Noveline - Tea Blending';
  }, []);

  return (
    <>
      <Header activeName={location.pathname} />
      <TeaBlendModule />
      <Footer scroll_position={100} />
    </>
  )
}

export default TeaBlendPage
