import React from 'react'
import BreadCrumb from '../BreadCrumb/BreadCrumb'
import './solution_pages.scss';
// import IOS from '../../assets/images/IOS.png';
// import ANDROID from '../../assets/images/Android.png';
// import HYBRID from '../../assets/images/Hybrid.png';
import AppdevelopmentImg from '../../assets/images/AppDevelopment.png'

const MobileApp = () => {
  return (
    <div className="container-fluid mt-4 mt-sm-4 mt-md-2 mt-lg-0 pt-5 pb-5 px-0 ">
      <BreadCrumb text="Solutions" />
      <div className='container mt-4'>
        <div className='margin-top'>
          <span className='pageMainHeading border-btm mx-0 px-0'>MOBILE APPLICATIONS</span>
        </div>
      </div>
      <div className='container mt-4'>
        <span className='pageMainHeading2 border-btm'>Crafting an Engaging Mobile Experience</span>
        <div className='mainContent mt-1'>Noveline Technologies offers End-to-end mobile application development for B2C and B2B environments.
          We craft sleek, user friendly and performance oriented mobile solutions, which results in customer delight and loyalty and sustained engagement.</div>
      </div>
      <div className='container mt-4'>
        <div className='row'>
          <div className='col-12 col-md-8'>
            <span className='pageMainHeading2 border-btm'>Focus Areas</span>
            <div className='mainContentHeadingRed'>Interface Design</div>
            <div className='mainContent'>
              We solve business tasks by creating user-friendly interfaces. Our products are easy on the eye,
              but aesthetics are on our list of priorities.
            </div>
            <div className='mainContentHeadingRed mt-3'>Engineering</div>
            <div className='mainContent'>
              We can create apps from scratch, as our team loves to take on a problem head-on and view it from
              multiple angles, constantly reviewing and updating processes and assumptions to boost efficiency.
            </div>
            <div className='mainContentHeadingRed mt-3'>Cross-Platform Coverage</div>
            <div className='mainContent'>
              We build mobile apps in all the popular mobile platforms and mobile-enabled
              technologies and help companies with their mobile app delivery strategy.
            </div>
          </div>
          <div className='col-11 col-md-4 border-left-brass px-3'>
            <span className='pageMainHeading2 border-btm'>App Development</span>
            <div className='mt-5 text-center'>
              <img src={AppdevelopmentImg} className="img-fluid" style={{ width: "90%" }} alt="App Development"></img>
            </div>
          </div>
        </div>
      </div>
      <div className='container mt-4'>
        <span className='pageMainHeading2 border-btm'>Our Objectives</span>
        <div className='mainContent mt-1'>From the initial idea to the final solution, the process is done with a clear vision of the business goals and functions. We strive to not just meet but exceed expectations.</div>
        <div className='mainContent mt-1'>Mobile and tablet based applications allow us to create captivating experiences for users to engage with brands and businesses. This allows companies to use the application
          for marketing, lead generation, information dispensation or even sales.</div>
        <div className='mainContent mt-1'>Creative use of mobile technology can grab the attention of the audience for longer periods, help in gaining insights, deepen customer loyalty, and provide constructive
          feedback and insightful behavioural data, which can provide direction for future decisions.</div>
      </div>
    </div>
  )
}

export default MobileApp