import Header from '../modules/HeaderModule/Header'
import CaseStudyModule from '../modules/CaseStudyModule/CaseStudyModule';
import Footer from '../modules/FooterModule/Footer'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const CaseStudyPage = () => {
  const location = useLocation();
  
  useEffect(() => {
    document.title = 'Noveline - Case Studies';
  }, []);

  return (
    <>
      <title>Noveline - Case Studies</title>
      <Header activeName={location.pathname} />
      <CaseStudyModule />
      <Footer scroll_position={0} />
    </>
  );
}




export default CaseStudyPage;