import React from 'react'
import BreadCrumb from '../BreadCrumb/BreadCrumb';
import './solution_pages.scss';
import AssetTrackingSystem from '../../assets/images/AssetTrackingSystem.png'

const TrackingSolutionModule = () => {
  return (
    <div className="container-fluid mt-4 mt-sm-4 mt-md-2 mt-lg-0 pt-5 pb-5 px-0 ">
      <BreadCrumb text="Solutions" />
      <div className='container mt-4'>
        <div className='margin-top'>
          <span className='pageMainHeading border-btm mx-0 px-0'>RFID BASED ASSET TRACKING SOLUTION</span>
        </div>
      </div>
      <div className='container mt-4'>
        <div className='mainContent'>Leverage Noveline's RFID asset tracking expertise to manage assets faster and more reliably.</div>
        <div className='mainContent mt-2'>Asset management refers to a set of business practices involved in identifying, discovering, procuring, managing, monitoring and disposing assets. It entails having a centralized
          view of all the assets present in an organisation/unit along with details. Having a thorough asset management process in place will empower you to make data-backed, strategic
          decisions about acquiring new assets, which will lead to cutting unnecessary expenses.</div>
      </div>
      <div className='container mt-4'>
        <span className='pageMainHeading2 border-btm'>Asset Tracking System</span>
        <div className='mt-3'>
          <img src={AssetTrackingSystem} className="img-fluid" style={{ width: "90%" }} alt="App Development"></img>
        </div>
      </div>
      <div className='container mt-4'>
        <span className='pageMainHeading2 border-btm'>Why RFID?</span>
        <div className='mainContent'>Radio Frequency Identification (RFID) makes Asset Tracking quicker and more accurate than barcode.</div>
        <div className='mainContentHeadingRed mt-4'>Easier</div>
        <div className='mainContent'>
          RFID eliminates the need for line-of-sight scanning. This means you can be several feet away from an asset and still read the tag. This means no more crawling under furniture or
          behind server racks to get to your asset tags.
        </div>

        <div className='mainContentHeadingRed mt-4'>Faster</div>
        <div className='mainContent'>
          RFID enables you to read multiple tags together. This results in much faster physical inventories, especially when you have lots of assets close together. Imagine the time you'll
          save auditing employee workstations, server rooms, and storage areas.
        </div>
        <div className='mainContentHeadingRed mt-4'>More Secure</div>
        <div className='mainContent'>
          RFID tags provide a high degree of security and asset authentication because they're more difficult to replicate than a simple barcode label. This ensures data integrity which
          helps improve overall accountability.
        </div>
        <div className='mainContentHeadingRed mt-4'>More Durable</div>
        <div className='mainContent'>
          If you can't read an asset tag, the system won't work. RFID tags are very durable, which helps to ensure readability. And there are a variety of RFID tags designed to work in hot,
          wet, dirty and or dusty conditions and asset physical characteristics.
        </div>
      </div>
    </div>

  )
}

export default TrackingSolutionModule;