import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import BreadCrumb from '../BreadCrumb/BreadCrumb'
import Image1 from '../../profile_images/Dr.NirmaJain.jpg'
import Image2 from '../../profile_images/DilipVarma.jpg'
import Image3 from '../../profile_images/Suoprakash.jpg'
import Image4 from '../../profile_images/VinodTakarkhedesir.jpeg'
import Image5 from '../../profile_images/ShrutiNayak.jpg'
import './MainTeamStyle.scss'

const TeamDetails = [
    {
        "Id": "01",
        "Name": "Dr. Nirmal Jain - Chief Business Strategist",
        "Details": "Phd in CS from University of Hawai, Business Management from Havard, MTech IIT Delhi, BTech IIT Mumbai.",
        "PastPosition": "MD at Tata Infotech, Sr. V.P. Asia Pacific at TCS, CEO ISG Novasoft",
        "Experience": "Dr. Jain spent 25 years with Tata Group Companies. At TCS as Sr. V.P., he was responsible for all the European and Asia/Pacific business, and part of the Strategic Management team. At Tata Infotech as Managing Director & CEO, he was responsible for making the company a leading Systems Integration Company in India. He was responsible for tripling its revenues over 5 years.",
        "Interests": "His interests are Business Strategy and Mentoring.",
        "Role": "Drives the business strategy and is an integral part of all major initiatives."
    },
    {
        "Id": "02",
        "Name": "Dilip Varma - Founder & Project Head",
        "Details": "Masters in Computer Applications.",
        "PastPosition": "Technical Architect at Hypertrends Inc, Project Mangement at Cambridesoft Inc, CTO at Sphinx Communications.",
        "Experience": "Dilip Varma has 20 years experience in the field of Information Technology. He has extensive experience as a CTO and Technical Architect especially in Microsoft Technologies. He has worked in teams in Enterprise Software Applications for MNC clients. Dilip has headed new technical initiatives. He was responsible for guiding and expanding the IT in his tenure at Sphinx.",
        "Interests": "His interests are Fintech and Edtech.",
        "Role": "Dilip Varma founded Noveline Technologies Pvt. Ltd. He heads the Project Division and is responsible for business expansion and technical collaboration.\n"
    },
    {
        "Id": "03",
        "Name": "Suprokash Goswami - Chief Technology Officer",
        "Details": "Post Graduate in IT from Symbiosis Pune.",
        "PastPosition": "Amazon, Net App.",
        "Experience": "Suprokash Goswami has 20 years experience. He has worked as CTO at Tapped Inc. At Amazon, he was the QA Manager for Kindle. Suprokash was the Transition Architect at NetApp.",
        "Interests": "His interests are Product Engineering.",
        "Role": "Suprokash functions as the Chief Technological Officer at Noveline."
    },
    {
        "Id": "04",
        "Name": "Vinod Takarkhede - Tech lead",
        "Details": "Post Graduate in Computer Science",
        "PastPosition": "EZ-Data (now: Ebix), Premier Technologies, Infospectrum (now: Persistant Systems)",
        "Experience": "Vinod has 23 years experience in IT. He has a wide range of experience in C, C++, MFC, AIX, Solaris, Linux, PHP, MySQL, Hybrid Mobile Apps. He also has domain experience in Network Security, VTMS and Insurance.",
        "Interests": "His interests are building robust scalable frameworks.",
        "Role": "Responsible for technical guidance and mentoring of mid-level technical team."
    },
    {
        "Id": "05",
        "Name": "Shruti Nayak - Project Management",
        "Details": "Masters in Information Technology",
        "PastPosition": "Senior Developer at Noveline, Lecturer in Mumbai University.",
        "Experience": "Shruti has grown from a Jr. Developer to heading the project management team at Noveline. She has experience in handling projects and interacting with the Tech team as well as clients.",
        "Interests": "Her interests are Enterprise Software.",
        "Role": "Shruti Nayak heads the Project Management team."
    }
]

const MainTeam = () => {
    const [modalShow, setModalShow] = useState(false);
    const [viewId, setViewId] = useState("");
    const [showDetails, setShowDetails] = useState({});

    function MyVerticallyCenteredModal(props) {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                animation={true}
            >
                <Modal.Header style={{ color: "white", background: "#ae1c28" }} >
                    <Modal.Title id="contained-modal-title-vcenter">
                        {showDetails?.Name}
                    </Modal.Title>
                    <button className='py-0 px-2 border-0 font-weight-bold rounded-circle bg-red text-white btnclose' onClick={props.onHide}>X</button>
                </Modal.Header>
                <Modal.Body>
                    <div className='bg p-3'>
                        <div className='teamHeading1'>{showDetails?.Name} </div>
                        <div className='teamHeading2'>{showDetails?.Details}</div>
                        <div className='teamHeading2'><span className='teamHeading3'>Past Position:&nbsp;</span>{showDetails?.PastPosition}</div>
                    </div>
                    <div className='mt-2'>
                        <span className='teamHeadingBlue'>Experience:</span>
                    </div>
                    <div className='mainContent mx-3'>
                        {showDetails?.Experience}
                    </div>
                    <div className='mt-2'>
                        <span className='teamHeadingBlue'>Interests:</span>
                    </div>
                    <div className='mainContent mx-3'>
                        {showDetails?.Interests}
                    </div>
                    <div className='mt-2'>
                        <span className='teamHeadingBlue'>Role:</span>
                    </div>
                    <div className='mainContent mx-3'>
                        {showDetails?.Role}
                    </div><br />
                </Modal.Body>
            </Modal>
        );
    }
    useEffect(() => {
        setShowDetails(TeamDetails.filter((i) => i.Id === viewId)[0])
        return () => { }
    }, [viewId])
    return (
        <>
            <div className="container-fluid mt-4 mt-sm-4 mt-md-2 mt-lg-0  pt-5 pb-5 px-0 ">{/*case-text*/}
                <BreadCrumb text="Team" />
                <div className="whos-speaking-area speakers">
                    <div className="container mt-4">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-3 p-3 px-md-2 mt-4 mt-lg-0 mt-md-0 mx-md-0 mx-5 mx-lg-4 d-flex justify-content-center">
                                <div className="speakers w-75">
                                    <div className="spk-img text-center px-3">
                                        <img className="img-fluid mt-3 w-100" src={Image1} alt="trainer-img" />
                                        <button
                                            onClick={() => {
                                                setModalShow(true);
                                                setViewId("01");
                                            }}>
                                            <div className="border-bottom knowMore">Know More</div></button>
                                    </div>
                                    <div className='p-3'>
                                        <div className='text-center teamHeadingBlue'>Dr. Nirmal Jain</div>
                                        <div className='text-center mainContent'>Chief Business Strategist </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 p-3 px-md-2 mt-4 mt-lg-0 mt-md-0 mx-md-0 mx-5 mx-lg-4 d-flex justify-content-center">
                                <div className="speakers w-75">
                                    <div className="spk-img text-center px-3">
                                        <img className="img-fluid mt-3 w-100" src={Image2} alt="trainer-img" />
                                        <button
                                            onClick={() => {
                                                setModalShow(true);
                                                setViewId("02");
                                            }}>
                                            <div className="border-bottom knowMore">Know More</div></button>
                                    </div>
                                    <div className='p-3'>
                                        <div className='text-center teamHeadingBlue'>Dilip Varma</div>
                                        <div className='text-center mainContent'>Founder & Project Head </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-12 col-md-3 p-3 px-md-2 mt-4 mt-lg-0 mt-md-0 mx-md-0 mx-5 mx-lg-4 d-flex justify-content-center">
                                <div className="speakers w-75">
                                    <div className="spk-img text-center px-3">
                                        <img className="img-fluid mt-3 w-100"
                                            src={Image3} alt="trainer-img" />

                                        <button
                                            onClick={() => {
                                                setModalShow(true);
                                                setViewId("03");
                                            }}>
                                            <div className="border-bottom knowMore">Know More</div>
                                        </button>

                                    </div>
                                    <div className='p-3'>
                                        <div className='text-center teamHeadingBlue'>Suprokash Goswami</div>
                                        <div className='text-center mainContent'>Chief Technology Officer </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-3 p-3 px-md-2 mt-4 mt-lg-0 mt-md-0 mx-md-0 mx-5 mx-lg-4 d-flex justify-content-center">
                                <div className="speakers w-75">
                                    <div className="spk-img text-center px-3">
                                        <img className="img-fluid mt-3 w-100" src={Image4} alt="trainer-img" />
                                        <button
                                            onClick={() => {
                                                setModalShow(true);
                                                setViewId("04");
                                            }}>
                                            <div className="border-bottom knowMore">Know More</div>
                                        </button>

                                    </div>
                                    <div className='p-3'>
                                        <div className='text-center teamHeadingBlue'>Vinod Takarkhede</div>
                                        <div className='text-center mainContent'>Tech lead</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 p-3 px-md-2 mt-4 mt-lg-0 mt-md-0 mx-md-0 mx-5 mx-lg-4 d-flex justify-content-center">
                                <div className="speakers w-75">
                                    <div className="spk-img text-center px-3">
                                        <img className="img-fluid mt-3 w-100" src={Image5} alt="trainer-img" />
                                        <button
                                            onClick={() => {
                                                setModalShow(true);
                                                setViewId("05");
                                            }}>
                                            <div className="border-bottom knowMore">Know More</div>
                                        </button>
                                    </div>
                                    <div className='p-3'>
                                        <div className='text-center teamHeadingBlue'>Shruti Nayak</div>
                                        <div className='text-center mainContent'>Project Management</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    )
}

export default MainTeam