import React from 'react';
import EnterpriseSoftwareImg from '../../assets/images/EnterpriseSoftwareImg.png';
import MobileApplicationsImg from '../../assets/images/MobileApplicationsImg.png';
import SoftwareOutsourcingImg from '../../assets/images/SoftwareOutsourcingImg.png';
import HelpDeskImg from '../../assets/images/HelpDeskImg.png';
import AssetManagementImg from '../../assets/images/AssetManagementImg.png';
import { FaLongArrowAltRight } from 'react-icons/fa';
import BreadCrumb from '../BreadCrumb/BreadCrumb';
import { useNavigate } from "react-router-dom"
import './SolutionsStyle.scss'
import WebApplicationImg from '../../assets/images/WebApplicationImg.png'
const SolutionsModule = () => {
    const navigate = useNavigate();
    return (
        <>
            {/* <!-- Content Start From Here --> */}
            <div className="container-fluid mt-3  mt-md-2 mt-lg-0  pt-5 pb-5 px-0 ">{/*case-text*/}
                <BreadCrumb text="Solutions" />

                <div className="container ps-3 pt-3">
                    <div className="row mx-auto mx-sm-auto mx-md-0 mx-lg-0 row-cols-1 row-cols-md-4 g-2">
                        {/*card-container*/}
                        <div className="CardTrans col-12 col-md-4 mt-4 mt-md-1 pt-4 pt-md-0">
                            <div className=" cards  h-100 ">
                                <div className='solutionImageBorderMain text-center mx-0 p-3'>
                                    <img src={WebApplicationImg} className="card-img-top " style={{ width: "30%" }} alt="webapp" />
                                </div>
                                <div className="dataHover card-body mb-4 pt-0">
                                    <div className="cardMainHeading ">Web Applications</div>
                                    <div className="cardSubContent ">From discovery to launch we craft Web Applications that are tailored to fit your business by providing attention to detail through each....
                                    </div>
                                </div>
                                <div className='vBottom'>
                                    <div className="bottomButtonHover text-end readMoreSolHover pt-2 pb-2">
                                        <button onClick={() => navigate("/solution/webapp_page")}>
                                            <div className='btm mx-1 row justify-content-end'>
                                                <div className='col-auto px-1'>
                                                    Read More
                                                </div>
                                                <div className='col-auto mx-0 iconHover3  p-0 mt-1 '>
                                                    <FaLongArrowAltRight />
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* --------------------------2-------------------- */}
                        {/* <div className="CardTrans col-sm-4 col-lg-4 col-md-4 mt-lg-1 pt-lg-0 mt-md-1 pt-md-0 mt-4 pt-4 "> */}
                        <div className="CardTrans col-12 col-md-4 mt-4 mt-md-1 pt-4 pt-md-0">
                            <div className=" cards  h-100 ">
                                <div className='solutionImageBorderMain text-center  mx-0 p-3'>
                                    <img src={EnterpriseSoftwareImg} className="card-img-top " style={{ width: "30%" }} alt="webapp" />
                                </div>
                                <div className="dataHover card-body mb-4 pt-0">
                                    <div className="cardMainHeading ">Enterprise Software</div>
                                    <div className="cardSubContent ">We create enterprise apps that are business-driven and user-focused, to increase profitability, efficiency and save costs....
                                    </div>
                                </div>
                                <div className='vBottom' >
                                    <div className="bottomButtonHover  text-end readMoreSolHover pt-2 pb-2">
                                        <button onClick={() => navigate("/solution/enterprise_page")}>
                                            <div className='btm mx-1 row justify-content-end'>
                                                <div className='col-auto px-1'>
                                                    Read More
                                                </div>
                                                <div className='col-auto mx-0 iconHover3  p-0 mt-1 '>
                                                    <FaLongArrowAltRight />
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ------------------------------3--------------------------------- */}
                        <div className="CardTrans col-12 col-md-4 mt-4 mt-md-1 pt-4 pt-md-0">

                            <div className=" cards  h-100 ">
                                <div className='solutionImageBorderMain text-center mx-0 p-3'>
                                    <img src={MobileApplicationsImg} className="card-img-top " style={{ width: "30%" }} alt="webapp" />
                                </div>
                                <div className="dataHover card-body  mb-4 pt-0">
                                    <div className="cardMainHeading ">Mobile Applications</div>
                                    <div className="cardSubContent ">Power driven functionalities with mesmerizing designs are the attributes we deliver for Mobile Apps....
                                    </div>
                                </div>
                                <div className='vBottom'>
                                    <div className="bottomButtonHover text-end readMoreSolHover pt-2 pb-2">
                                        <button onClick={() => navigate("/solution/mobileapp_page")}>
                                            <div className='btm mx-1 row justify-content-end'>
                                                <div className='col-auto px-1'>
                                                    Read More
                                                </div>
                                                <div className='col-auto mx-0 iconHover3  p-0 mt-1 '>
                                                    <FaLongArrowAltRight />
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ---------------------4------------------- */}
                        {/* <div className="CardTrans col-12 col-lg-3 col-md-4 mt-lg-1 pt-lg-0 mt-md-1 pt-md-0 mt-4 pt-4 ">

                            <div className=" cards  h-100 ">
                                <div className='solublueBgImg text-center mx-0 p-3'>
                                    <img src={SoftwareOutsourcingImg} className="card-img-top " style={{ width: "50%" }} alt="webapp" />
                                </div>
                                <div className="dataHover card-body mb-4">
                                    <div className="cardMainHeading ">Software Outsourcing</div>
                                    <div className="cardSubContent ">A dedicated development centre with us is an engagement model with productivity, exclusivity of teams and integrity....
                                    </div>
                                </div>
                                <div className='vBottom'>
                                    <div className="bottomButtonHover text-end readMoreSolHover pt-2 pb-2">
                                        <button onClick={() => navigate("/solution/outsourcing_page")}>
                                            <div className='btm mx-1 row justify-content-end'>
                                                <div className='col-auto px-1'>
                                                    Read More
                                                </div>
                                                <div className='col-auto mx-0 iconHover3  p-0 mt-1 '>
                                                    <FaLongArrowAltRight />
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                          */}
                        {/* -------------------------------------4---------------------------------- */}
                        {/*card-container*/}
                        <div className="CardTrans col-12 col-md-4 mt-4 mt-md-3 pt-4 pt-md-0 ">

                            <div className=" cards  h-100 ">
                                <div className=' solutionImageBorderMain text-center mx-0 p-3'>
                                    <img src={SoftwareOutsourcingImg} className="card-img-top " style={{ width: "30%" }} alt="webapp" />
                                </div>
                                <div className="dataHover card-body mb-4 pt-0">
                                    <div className="cardMainHeading ">Software Outsourcing</div>
                                    <div className="cardSubContent ">A dedicated development centre with us is an engagement model with productivity, exclusivity of teams and integrity....
                                    </div>
                                </div>
                                <div className='vBottom'>
                                    <div className="bottomButtonHover text-end readMoreSolHover pt-2 pb-2">
                                        <button onClick={() => navigate("/solution/outsourcing_page")}>
                                            <div className='btm mx-1 row justify-content-end'>
                                                <div className='col-auto px-1'>
                                                    Read More
                                                </div>
                                                <div className='col-auto mx-0 iconHover3  p-0 mt-1 '>
                                                    <FaLongArrowAltRight />
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* -------------------------------------5---------------------------------- */}
                        <div className="CardTrans col-12 col-md-4 mt-4 mt-md-3 pt-4 pt-md-0">

                            <div className=" cards  h-100 ">
                                <div className='solutionImageBorderMain text-center mx-0 p-3'>
                                    <img src={HelpDeskImg} className="card-img-top " style={{ width: "30%" }} alt="webapp" />
                                </div>
                                <div className="dataHover card-body mb-4 pt-0" >
                                    <div className="cardMainHeading ">Software Support Help Desk</div>
                                    <div className="cardSubContent ">We provide IT help desk services to companies for user and solution support of solutions that are on premise or on Cloud....
                                    </div>
                                </div>
                                <div className='vBottom'>
                                    <div className="bottomButtonHover text-end readMoreSolHover pt-2 pb-2">
                                        <button onClick={() => navigate("/solution/helpdesk_page")}>
                                            <div className='btm mx-1 row justify-content-end'>
                                                <div className='col-auto px-1'>
                                                    Read More
                                                </div>
                                                <div className='col-auto mx-0 iconHover3  p-0 mt-1 '>
                                                    <FaLongArrowAltRight />
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* -----------------6----------------- */}
                        <div className="CardTrans col-12 col-md-4 mt-4 mt-md-3 pt-4 pt-md-0">

                            <div className=" cards  h-100 ">
                                <div className='solutionImageBorderMain text-center mx-0 p-3'>
                                    <img src={AssetManagementImg} className="card-img-top " style={{ width: "30%" }} alt="webapp" />
                                </div>
                                <div className="dataHover card-body mb-4 pt-0">
                                    <div className="cardMainHeading ">Asset Management</div>
                                    <div className="cardSubContent">RFID based  solution, to track and conduct the  Audit of button large number of heterogeneous assets, spread over buildings....
                                    </div>
                                </div>
                                <div className='vBottom'>
                                    <div className="bottomButtonHover text-end readMoreSolHover pt-2 pb-2">
                                        <button onClick={() => navigate("/solution/tracking_page")}>
                                            <div className='btm mx-1 row justify-content-end'>
                                                <div className='col-auto px-1'>
                                                    Read More
                                                </div>
                                                <div className='col-auto mx-0 iconHover3  p-0 mt-1' >
                                                    <FaLongArrowAltRight />
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default SolutionsModule;