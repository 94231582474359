import Header from '../modules/HeaderModule/Header'
import CaseStudyReplaceModule from '../modules/CaseStudyReplaceModule/CaseStudyReplaceModule'
import Footer from '../modules/FooterModule/Footer'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const CaseStudyReplacePage = () => {
  const location = useLocation();


  useEffect(() => {
    document.title = 'Noveline - Case Studies';
  }, []);

  return (
    <>
      <title>Noveline - Case Studies</title>
      <Header activeName={location.pathname} />
      <CaseStudyReplaceModule />
      <Footer scroll_position={0} />
    </>
  );
}

export default CaseStudyReplacePage;
