import Header from '../modules/HeaderModule/Header'
import SolutionsModule from '../modules/SolutionsModule/SolutionsModule';
import Footer from '../modules/FooterModule/Footer'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const SolutionsPage = () => {
  const location = useLocation();
  
  useEffect(() => {
    document.title = 'Noveline - Solutions';
  }, []);

  return (
    <>
      <Header activeName={location.pathname} />
      <SolutionsModule />
      <Footer scroll_position={100} />
    </>
  );
}




export default SolutionsPage