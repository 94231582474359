import React from 'react';
import './BreadCrumb.scss';

const BreadCrumb = ({text}) => {
    return (
        <>
         <div className="container-fluid ps-5 pb-2 pageHeading redBg fix-red">
            {text}
        </div>
        </>
    )
}

export default BreadCrumb;