import React from 'react'
import Header from '../modules/HeaderModule/Header'
import Footer from '../modules/FooterModule/Footer'
import MobileAppSolutionModule from '../modules/SolutionsPagesModule/MobileAppSolutionModule'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const MobilePage = () => {
  const location = useLocation();


  useEffect(() => {
    document.title = 'Noveline - Mobile Application';
  }, []);

  return (
    <>
      <Header activeName={location.pathname} />
      <MobileAppSolutionModule />
      <Footer scroll_position={100} />
    </>
  )
}

export default MobilePage