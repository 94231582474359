import React from 'react'
import Header from '../modules/HeaderModule/Header'
import Footer from '../modules/FooterModule/Footer'
import EnterpriseSolutionPage from '../modules/SolutionsPagesModule/EnterpriseSolutionPage'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const EnterprisePage = () => {
  const location = useLocation();
  
  useEffect(() => {
    document.title = 'Noveline - Enterprise Solutions';
  }, []);

  return (
    <>
      <Header activeName={location.pathname} />
      <EnterpriseSolutionPage />
      <Footer scroll_position={100} />
    </>
  )
}

export default EnterprisePage