import logo from '../../assets/images/noveline_logo_small.png';

const DeveloperUseOnly = () => {

    return (
        <>
            <img id="scream" width="220" height="277" className="d-none"
                src={logo} alt="The Scream"></img>

            <canvas id="myCanvas" width={0} height={0}></canvas>
        </>
    )
}

export default DeveloperUseOnly;