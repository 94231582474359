import React from 'react'
import Header from '../modules/HeaderModule/Header'
import WebAppSolutionModule from '../modules/SolutionsPagesModule/WebApplicationSolutionModule'
import Footer from '../modules/FooterModule/Footer'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const WebAppPage = () => {
  const location = useLocation();

  useEffect(() => {
    document.title = 'Noveline - Web Applications';
  }, []);
  return (
    <>
      <Header activeName={location.pathname} />
      <WebAppSolutionModule />
      <Footer scroll_position={100} />
    </>
  )
}

export default WebAppPage