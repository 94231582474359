import React from 'react'
import Header from '../modules/HeaderModule/Header'
import TeamModule from '../modules/TeamModule/TeamModule'
import Footer from '../modules/FooterModule/Footer'
import { useLocation } from 'react-router-dom';

const TeamsPage = () => {
  const location = useLocation();
  return (
    <>
      <Header activeName={location.pathname} />
      <TeamModule />
      <Footer scroll_position={100} />
    </>
  )
}

export default TeamsPage