import React from 'react'
import client1 from '../../assets/images/HUL.jpg';
import client2 from '../../assets/images/TATASteel.jpg';
import client3 from '../../assets/images/Indian.jpg';
import client4 from '../../assets/images/sphinx.jpg';
import client5 from '../../assets/images/hyper.jpg';
import client6 from '../../assets/images/TataVerse.jpg';
import client7 from '../../assets/images/HDFCLife.jpg';
import client9 from '../../assets/images/Keynivesh.jpg';
import client10 from '../../assets/images/Fincart.jpg';
import client8 from '../../assets/images/Symphony.jpg';

const carousel = ({ initiate_slide }) => {
  return (
    <>
      <div className='row justify-content-evenly'>
        <div className="slider">
          {/* <div className="slide-track"> */}
          <div className={initiate_slide}>
            <div className="slide">
              <img src={client1} height="100" width="250" alt="" />
            </div>
            <div className="slide">
              <img src={client2} height="100" width="250" alt="" />
            </div>
            <div className="slide">
              <img src={client3} height="100" width="250" alt="" />
            </div>
            <div className="slide">
              <img src={client4} height="100" width="250" alt="" />
            </div>
            <div className="slide">
              <img src={client5} height="100" width="250" alt="" />
            </div>
            <div className="slide">
              <img src={client6} height="100" width="250" alt="" />
            </div>
            <div className="slide">
              <img src={client7} height="100" width="250" alt="" />
            </div>
            <div className="slide">
              <img src={client8} height="100" width="250" alt="" />
            </div>
            <div className="slide">
              <img src={client9} height="100" width="250" alt="" />
            </div>
            <div className="slide">
              <img src={client10} height="100" width="250" alt="" />
            </div>
            {/* <div className="slide">
              <img src={client11} height="100" width="250" alt="" />
            </div> */}
            {/* <div className="slide">
              <img src={client1} height="100" width="250" alt="" />
            </div>
            <div className="slide">
              <img src={client2} height="100" width="250" alt="" />
            </div>
            <div className="slide">
              <img src={client3} height="100" width="250" alt="" />
            </div>
            <div className="slide">
              <img src={client4} height="100" width="250" alt="" />
            </div>
            <div className="slide">
              <img src={client5} height="100" width="250" alt="" />
            </div>
            <div className="slide">
              <img src={client6} height="100" width="250" alt="" />
            </div>
            <div className="slide">
              <img src={client7} height="100" width="250" alt="" />
            </div>
            <div className="slide">
              <img src={client8} height="100" width="250" alt="" />
            </div>
            <div className="slide">
              <img src={client9} height="100" width="250" alt="" />
            </div>
            <div className="slide">
              <img src={client10} height="100" width="250" alt="" />
            </div>
            <div className="slide">
              <img src={client11} height="100" width="250" alt="" />
            </div> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default carousel;