/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import img1 from '../../assets/images/wheelImg.png';
import img2 from '../../assets/images/wheelImg2.png';
import img3 from '../../assets/images/mobileImg.png';
import img4 from '../../assets/images/desktopImg.png';
import img5 from '../../assets/images/lightImgRed.png';
import offeringImg1 from '../../assets/images/OfferingImg_1.png';
import offeringImg2 from '../../assets/images/OfferingImg_2.png';
import offeringImg3 from '../../assets/images/OfferingImg_3.png';
import offeringImg4 from '../../assets/images/OfferingImg_4.png';
import offeringImg5 from '../../assets/images/OfferingImg_5.png';
import teaBlend from '../../assets/images/tea3.png';
import teaBlBrassManagement from '../../assets/images/Brass2.png';
import Verticales1 from '../../assets/images/Verticals_1.png';
// import Verticales1red from '../../assets/images/Verticals_1red.png';
// import Verticales2red from '../../assets/images/Verticals_2red.png';
// import Verticales3red from '../../assets/images/Verticals_3red.png';
// import Verticales4red from '../../assets/images/Verticals_4red.png';
// import Verticales5red from '../../assets/images/Verticals_5red.png';
// import Verticales6red from '../../assets/images/Verticals_6red.png';
// import Verticales7red from '../../assets/images/Verticals_7red.png';
// import Verticales8red from '../../assets/images/Verticals_8red.png';
// import Verticales9red from '../../assets/images/Verticals_9red.png';
import Verticales2 from '../../assets/images/Verticals_2.png';
import Verticales3 from '../../assets/images/Verticals_3.png';
import Verticales4 from '../../assets/images/Verticals_4.png';
import Verticales5 from '../../assets/images/Verticals_5.png';
import Verticales6 from '../../assets/images/Verticals_6.png';
import Verticales7 from '../../assets/images/Verticals_7.png';
import Verticales8 from '../../assets/images/Verticals_8.png';
import Verticales9 from '../../assets/images/Verticals_9.png';
import WebApplicationImg from '../../assets/images/WebApplicationImg.png';
import EnterpriseSoftwareImg from '../../assets/images/EnterpriseSoftwareImg.png';
import MobileApplicationsImg from '../../assets/images/MobileApplicationsImg.png';
import SoftwareOutsourcingImg from '../../assets/images/SoftwareOutsourcingImg.png';
import HelpDeskImg from '../../assets/images/HelpDeskImg.png';
import AssetManagementImg from '../../assets/images/AssetManagementImg.png';


import './MainContentStyle.scss';
import CarouselFade from './CarouselFade';
import { useNavigate } from "react-router-dom";
import { Fade, Bounce } from 'react-reveal';

const MainContentModule = () => {
    const navigate = useNavigate();

    return (
        <>
            <div className="homeMainContentBg">
                <div className="container-fluid pt-5 paddingTopBottom g-4">
                    <div className='mt-3 p-3'>
                        <div className="row pt-md-5 pt-0 pb-2 justify-content-center">
                            <div className="col-11 col-md-8 col-lg-7 ps-0 ps-lg-5 classHeight my-md-5 my-2" >
                                <CarouselFade />
                            </div>
                            <div className="col-12 col-lg-3 ">
                                <div className="text-left mt-3 pb-5 WheelContainer">
                                    <img src={img1} className="WheelclockwiseSpin" width="200" height="230" alt='WheelclockwiseSpin' />
                                    <div className="text-end wheel2">
                                        <img src={img2} className="WheelantiClockwiseSpin" width="150" height="150" alt="WheelantiClockwiseSpin" />
                                    </div>
                                </div>
                                <div className="text-end mt-5 pt-5 pe-5 me-5 mobileAnimation">
                                    <img src={img3} width="100" height="176" alt="img3" />
                                </div>
                            </div>
                            <div className="col-12 col-lg-2">
                                <div className="text-center mt-5 pb-0 pb-lg-5 ps-0 ps-lg-3 desktopAnimation">
                                    <img src={img4} width="150" height="124" alt="Img4" />
                                </div>
                                <div className="text-start mt-0 mt-lg-5 pt-0 pt-lg-4">
                                    <img src={img5} className="mt-2 bulbImage" width="130" height="133" alt="Img5" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mb-5">
                <div className="sectionMainHeading border-btm p-1 mt-4">OFFERINGS</div>
                <div className="container h-100 ps-5 pt-3">
                    <Fade bottom>
                        <div className="row justify-content-center h-100">
                            <div className="col-12 col-sm-6 col-lg-4">
                                <div className="card h-100">
                                    <div className="row abc justify-content-center h-100 mx-0">
                                        <div className="col-12 col-md-4 blueBg d-flex">
                                            <img src={offeringImg1} className="img-fluid mx-auto my-1 my-md-0 align-self-center" alt="TechnologyConsulting" />
                                        </div>
                                        <div className="col-12 col-md-8 offeringsCardContainer px-0">
                                            <div className="card-body px-3 ">
                                                <div className="cardMainHeading">Technology Consulting</div>
                                                <div className="cardSubContent">
                                                    - Proof of Concepts<br></br>
                                                    - Feasibility Study<br></br>
                                                    - Project Consultancy<br></br>
                                                    - Prototyping
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4 mt-3 mt-lg-0">
                                <div className="card h-100">
                                    <div className="row justify-content-center h-100 mx-0">
                                        <div className="col-12 col-md-4 blueBg d-flex">
                                            <img src={offeringImg5} className="img-fluid mx-auto my-1 my-md-0 align-self-center" alt="OffshoreDevelopment" />
                                        </div>
                                        <div className="col-12 col-md-8 offeringsCardContainer px-0">
                                            <div className="card-body px-3   ">
                                                <div className="cardMainHeading">Technology Solutions</div>
                                                <div className="cardSubContent">
                                                    - Integrated Solutions<br></br>
                                                    - Web Applications<br></br>
                                                    - Mobile Applications<br></br>
                                                    - Enterprise Software
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4 mt-3 mt-lg-0">
                                <div className="card h-100">
                                    <div className="row justify-content-center h-100 mx-0">
                                        <div className="col-12 col-md-4 blueBg d-flex">
                                            <img src={offeringImg3} className="img-fluid mx-auto my-1 my-md-0 align-self-center" alt="OffshoreDevelopment" />
                                        </div>
                                        <div className="col-12 col-md-8 offeringsCardContainer px-0">
                                            <div className="card-body px-3  ">
                                                <div className="cardMainHeading">Partner Solutions</div>
                                                <div className="cardSubContent">
                                                    - Retrospect Backup Solutions<br></br>
                                                    - DotR RFID Handheld Scanner<br></br>
                                                    - Confidex RFID Tags
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4 mt-3">
                                <div className="card h-100">
                                    <div className="row justify-content-center h-100 mx-0">
                                        <div className="col-12 col-md-4 blueBg d-flex">
                                            <img src={offeringImg4} className="img-fluid mx-auto my-1 my-md-0 align-self-center" alt="TechnologyConsulting" />
                                        </div>
                                        <div className="col-12 col-md-8 offeringsCardContainer px-0">
                                            <div className="card-body px-3  ">
                                                <div className="cardMainHeading">Support Services</div>
                                                <div className="cardSubContent">
                                                    - Application Maintenance<br></br>
                                                    - Application Helpdesk<br></br>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4 mt-3">
                                <div className="card h-100">
                                    <div className="row justify-content-center h-100 mx-0">
                                        <div className="col-12 col-md-4 blueBg d-flex">
                                            <img src={offeringImg2} className="img-fluid mx-auto my-1 my-md-0 align-self-center" alt="OffshoreDevelopment" />
                                        </div>
                                        <div className="col-12 col-md-8 offeringsCardContainer px-0">
                                            <div className="card-body px-3 ">
                                                <div className="cardMainHeading">Offshore Development</div>
                                                <div className="cardSubContent">
                                                    - Client Managed<br></br>
                                                    - Noveline Managed<br></br>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
            </div>


            <div className="container">
                <Fade>
                    <div className='sectionMainHeadingSecondary border-btm p-1 mt-4'>SOLUTIONS</div>
                </Fade>
                <div className="container ps-5 pt-3 mainContainerFluid">
                    <div className="row g-4">
                        <Bounce bottom>
                            <div className="col-12 col-sm-4 col-lg-4 mt-4 mb-4 mt-md-5 mb-md-5">
                                <div className="card mb-3 h-100">
                                    <div className="card-header cardSolutionHeader bgRed text-center w-75 mx-auto position-relative"
                                        style={{ top: "-1.3rem" }}>Web Applications</div>
                                    <div className="card-body px-2 pt-0 pb-2">
                                        <div className='row  solutionImageBorder  justify-content-center'>
                                            <img className='col-auto w-25 mb-2' src={WebApplicationImg} />


                                        </div>
                                        <div className="cardSubContentSolution mx-2">
                                            From discovery to launch we craft Web Applications
                                            that are tailored to fit your business by providing attention
                                            to detail through each step
                                        </div>
                                    </div>
                                    <div className='card-footer cardFooter'>
                                        <div className="text-end readMoreHome pt-2">
                                            <button className='stretched-link' onClick={() => navigate("/solution/webapp_page")}><span>Read More&nbsp;</span><span className='readMoreArrow'>{'>'}</span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-4 col-lg-4 mt-4 mb-4 mt-md-5 mb-md-5">
                                <div className="card mb-3 h-100">
                                    <div className="card-header cardSolutionHeader bgRed text-center w-75 mx-auto position-relative" style={{ top: "-1.3rem" }}>Enterprise Software</div>
                                    <div className="card-body px-2 pt-0 pb-2">
                                        <div className='solutionImageBorder row justify-content-center'>
                                            {/* <img className='col-auto w-25 mb-2' src={EnterpriseSoftwareImg} /> */}
                                            <img className='col-auto w-25 mb-2' src={EnterpriseSoftwareImg} />
                                        </div>
                                        <div className="cardSubContentSolution mx-2">

                                            We create enterprise apps that are business-driven and
                                            user-focused, to increase profitability, efficiency and
                                            save costs
                                        </div>
                                    </div>
                                    <div className='card-footer cardFooter'>
                                        <div className="text-end readMoreHome pt-2"><button className='stretched-link' onClick={() => navigate("/solution/enterprise_page")}><span>Read More&nbsp;</span><span className='readMoreArrow'>{'>'}</span></button></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-4 col-lg-4 mt-4 mb-4 mt-md-5 mb-md-5">
                                <div className="card mb-3 h-100">
                                    <div className="card-header bgRed cardSolutionHeader text-center w-75 mx-auto position-relative" style={{ top: "-1.3rem" }}>Mobile Applications</div>
                                    <div className="card-body px-2 pt-0 pb-2">
                                        <div className='solutionImageBorder row justify-content-center'>
                                            <img className='col-auto w-25 mb-2' src={MobileApplicationsImg} />
                                        </div>
                                        <div className="cardSubContentSolution mx-2">

                                            Power driven functionalities with mesmerizing designs are
                                            the attributes we deliver for Mobile Apps<br /><br />
                                        </div>
                                    </div>
                                    <div className='card-footer cardFooter'>
                                        <div className="text-end readMoreHome pt-2"><button className='stretched-link' onClick={() => navigate("/solution/mobileapp_page")}><span>Read More&nbsp;</span><span className='readMoreArrow'>{'>'}</span></button></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-4 col-lg-4 mt-4 mb-4 mt-md-5 mb-md-5">
                                <div className="card mb-3 h-100">
                                    <div className="card-header cardSolutionHeader bgRed text-center w-75 mx-auto position-relative" style={{ top: "-1.3rem" }}>Software Outsourcing</div>
                                    <div className="card-body px-2 pt-0 pb-2">
                                        <div className='solutionImageBorder row justify-content-center'>
                                            <img className='col-auto w-25 mb-2' src={SoftwareOutsourcingImg} />
                                        </div>
                                        <div className="cardSubContentSolution mx-2">
                                            A dedicated development centre with us is an engagement
                                            model with productivity, exclusivity of teams and integrity
                                        </div>
                                    </div>
                                    <div className='card-footer cardFooter'>
                                        <div className="text-end readMoreHome pt-2"><button className='stretched-link' onClick={() => navigate("/solution/outsourcing_page")}><span>Read More&nbsp;</span><span className='readMoreArrow'>{'>'}</span></button></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-4 col-lg-4 mt-4 mb-4 mt-md-5 mb-md-5">
                                <div className="card mb-3 h-100">
                                    <div className="card-header cardSolutionHeader bgRed text-center w-75 mx-auto position-relative" style={{ top: "-1.3rem" }}>Software Support Help Desk</div>
                                    <div className="card-body px-2 pt-0 pb-2">
                                        <div className='solutionImageBorder row justify-content-center'>
                                            <img className='col-auto w-25 mb-2' src={HelpDeskImg} />
                                        </div>
                                        <div className="cardSubContentSolution mx-2">
                                            We provide IT help desk services to companies for user
                                            and solution support of solutions that are on premise
                                            or on Cloud
                                        </div>
                                    </div>
                                    <div className='card-footer cardFooter'>
                                        <div className="text-end readMoreHome pt-2"><button className='stretched-link' onClick={() => navigate("/solution/helpdesk_page")}><span>Read More&nbsp;</span><span className='readMoreArrow'>{'>'}</span></button></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-4 col-lg-4 mt-4 mb-4 mt-md-5 mb-md-5">
                                <div className="card mb-3 h-100">
                                    <div className="card-header cardSolutionHeader bgRed text-center w-75 mx-auto position-relative" style={{ top: "-1.3rem" }}>Asset Management</div>
                                    <div className="card-body px-2 pt-0 pb-2">
                                        <div className='solutionImageBorder row justify-content-center'>
                                            <img className='col-auto w-25 mb-2' src={AssetManagementImg} />
                                        </div>
                                        <div className="cardSubContentSolution mx-2">
                                            RFID based solution, to track and conduct Audit of a large
                                            number of heterogeneous assets, spread over buildings<br /><br />
                                        </div>
                                    </div>
                                    <div className='card-footer cardFooter'>
                                        <div className="text-end readMoreHome pt-2">
                                            <button className='stretched-link' onClick={() => navigate("/solution/tracking_page")}><span>Read More&nbsp;</span><span className='readMoreArrow'>{'>'}</span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Bounce >
                    </div >
                </div >
            </div >


            <div className="container ps-5 pt-4 mainContainerFluid verticalSection">
                <div className='row'>
                    <div className="col-12 col-sm-6 col-lg-5">
                        <div className='pageMainHeading' style={{
                            boxShadow: "4px 4px #acacac",
                            backgroundColor: "#21468B",
                            color: "#fdfbf6",
                            textAlign: "center"
                        }}>CASE STUDIES</div>
                        <div className="card mb-2 mt-2">
                            <div className="row m-0">
                                <div className="col-md-4 blueBg d-flex align-items-center">
                                    <img src={teaBlend} className="img-fluid border-0 mx-auto d-block p-2" alt="OffshoreDevelopment" />
                                </div>
                                <div className="col-md-8">
                                    <div className="card-body cursor-pointer p-1">
                                        <div className="cardMainHeading">Tea Blends</div>
                                        <div className="cardDescHeading">Optimize Cost & Maintain Quality </div>
                                        <div className="cardSubContent">
                                            How did we save significant costs of Tea blends
                                            for a leading Tea manufacturer despite it being a non-linear multi-faceted problem?</div>
                                        <div className="readMoreCSHome pt-2">
                                            <button onClick={() => navigate("/CaseStudy/Tea_blend")}>Read More {'>'}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card mb-3">
                            <div className="row m-0">
                                <div className="col-md-4 blueBg d-flex align-items-center">
                                    <img src={teaBlBrassManagement}
                                        className="img-fluid border-0 mx-auto d-block p-2" alt="OffshoreDevelopment" />
                                </div>
                                <div className="col-md-8 px-2">
                                    <div className="card-body cursor-pointer p-1">
                                        <div className="cardMainHeading">BRASS</div>
                                        <div className="cardDescHeading">Centralised Management of Records</div>
                                        <div className="cardSubContent">
                                            How was the challenge of inward and outward flow and accessibility of records in a marine defence organisation with central, sub-units and ships met?</div>
                                        <div className="readMoreCSHome pt-2"><button onClick={() => navigate("/CaseStudy/Brass")}>Read More {'>'}</button></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-sm-6 col-lg-7'>
                        <div className='pageMainHeading' style={{
                            boxShadow: "4px 4px #acacac",
                            backgroundColor: "#21468B",
                            color: "#fdfbf6",
                            textAlign: "center"
                        }}>VERTICALS</div>
                        {/* ---------------------------------------------1-------- */}
                        <div className="row card-container mt-2">
                            <Fade>
                                <div className="col-12 col-sm-6 col-lg-4 mb-2">
                                    <div className="card h-100 cardred1 py-3">
                                        <div className='my-auto'>
                                            <div className='text-center'>
                                                <img src={Verticales1} className="  mx-auto" alt="FMCG" />
                                            </div>
                                            <div className="card-body p-0">
                                                <div className="cardMainHeadingVerticles mt-1">FMCG</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                            {/* ---------------------------------------------2-------- */}
                            <Fade>
                                <div className="col-12 col-sm-6 col-lg-4 mb-2">
                                    <div className="card h-100 cardred1 py-3  
                                    ">
                                        <div className='my-auto'>
                                            <div className='text-center'>
                                                <img src={Verticales4} className="img-fluid mx-auto" alt="Hospitality" />
                                            </div>
                                            <div className="card-body p-0">
                                                <div className="cardMainHeadingVerticles mt-1">Hospitality</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                            {/* ---------------------------------------------3-------- */}
                            <Fade>
                                <div className="col-12 col-sm-6 col-lg-4 mb-2">
                                    <div className="card h-100 cardred1 py-3 text-offwhite">
                                        <div className='my-auto'>
                                            <div className='text-center'>
                                                <img src={Verticales7} className="img-fluid mx-auto" alt="Education" />
                                            </div>
                                            <div className="card-body p-0">
                                                <div className="cardMainHeadingVerticles mt-1">Education</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                            {/* ---------------------------------------------4-------- */}
                            <Fade>
                                <div className="col-12 col-sm-6 col-lg-4 mb-2 mt-2">
                                    <div className="card h-100 cardred1 py-3  text-offwhite">
                                        <div className='my-auto'>
                                            <div className='text-center'>
                                                <img src={Verticales2} className="img-fluid mx-auto" alt="Government" />
                                            </div>
                                            <div className="card-body p-0">
                                                <div className="cardMainHeadingVerticles mt-1">Government</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                            {/* ---------------------------------------------5-------- */}
                            <Fade>
                                <div className="col-12 col-sm-6 col-lg-4 mb-2 mt-2">
                                    <div className="card h-100 cardred1 py-3  text-offwhite">
                                        <div className='my-auto'>
                                            <div className='text-center'>
                                                <img src={Verticales5} className="img-fluid mx-auto" alt="Tea" />
                                            </div>
                                            <div className="card-body p-0">
                                                <div className="cardMainHeadingVerticles mt-1">Tea</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                            {/* ---------------------------------------------6-------- */}
                            <Fade>
                                <div className="col-12 col-sm-6 col-lg-4 mb-2 mt-2">
                                    <div className="card h-100 cardred1 py-3  text-offwhite">
                                        <div className='my-auto'>
                                            <div className='text-center'>
                                                <img src={Verticales6} className="img-fluid mx-auto" alt="Fintech" />
                                            </div>
                                            <div className="card-body p-0">
                                                <div className="cardMainHeadingVerticles mt-1">Fintech</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                            {/* ---------------------------------------------7-------- */}
                            <Fade>
                                <div className="col-12 col-sm-6 col-lg-4 mb-2 mt-2">
                                    <div className="card h-100 cardred1 py-3  text-offwhite">
                                        <div className='my-auto'>
                                            <div className='text-center'>
                                                <img src={Verticales3} className="img-fluid mx-auto" alt="Oils" />
                                            </div>
                                            <div className="card-body p-0">
                                                <div className="cardMainHeadingVerticles mt-1">Oils</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                            {/* ---------------------------------------------8-------- */}
                            <Fade>
                                <div className="col-12 col-sm-6 col-lg-4 mb-2 mt-2">
                                    <div className="card h-100 cardred1 py-3  text-offwhite">
                                        <div className='my-auto'>
                                            <div className='text-center'>
                                                <img src={Verticales8} className="img-fluid mx-auto" alt="Advertising" />
                                            </div>
                                            <div className="card-body p-0">
                                                <div className="cardMainHeadingVerticles mt-1">Advertising</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                            {/* ---------------------------------------------9-------- */}
                            <Fade>
                                <div className="col-12 col-sm-6 col-lg-4 mb-2 mt-2">
                                    <div className="card h-100 cardred1 py-3  text-offwhite">
                                        <div className='my-auto'>
                                            <div className='text-center'>
                                                <img src={Verticales9} className="img-fluid mx-auto" alt="Insurance" />
                                            </div>
                                            <div className="card-body p-0">
                                                <div className="cardMainHeadingVerticles mt-1">Insurance</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MainContentModule;