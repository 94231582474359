import React from 'react'
import BreadCrumb from '../BreadCrumb/BreadCrumb'
// import Integration from '../../assets/images/Integration-Navigation.png'
import './solution_pages.scss';
import img1EP from '../../assets/images/idea.png';
import img2EP from '../../assets/images/Implement.png';
import img3EP from '../../assets/images/EnterpriseTechnology.png';

// import Mobile from '../../assets/images/Mobile.png'
// import Partnerships from '../../assets/images/Partnerships.png'
// import Economy from '../../assets/images/API-Economy.png'
// import IOT from '../../assets/images/IOT.png'
// import SAASOfferings from '../../assets/images/SAAS-Offerings.png'

import { Fade } from 'react-reveal';

const EnterpriseSolutionPage = () => {
  return (

    <div className="container-fluid mt-4 mt-sm-4 mt-md-2 mt-lg-0 pt-5 pb-5 px-0 ">
      <BreadCrumb text="Solutions" />
      <div className='container mt-4'>
        <div className='margin-top'>
          <span className='pageMainHeading border-btm mx-0 px-0'>ENTERPRISE SOFTWARE</span>
        </div>
      </div>
      <div className='container mt-4'>
        <div className='mainContent'>Innovation and Evolution are key factors that provide businesses the cutting edge to forge ahead of competition.
          We can guide you in leveraging the latest and best in technology. You can benefit from our experience in customised technology solutions, modernization of legacy systems,
          integration of diverse solutions.</div>
        <div className='mainContent mt-2'>We can create enterprise solutions from scratch, enhance existing systems, conduct feasibility studies, and build “Proof of Concepts” to bring in transformation to your IT landscape.</div>
        <div className='mainContent mt-2'>We delve deep into your business processes to create solutions that tackle organizational scenarios, be it content management, workflow, production process or internal
          communication. We translate your needs into solutions that are in tune with your industry specifics and unique business traits.</div>
      </div>
      <div className='container mt-4'>
        <span className='pageMainHeading2 border-btm'>Current Status of Systems</span>
        <div className='mainContent'>
          The Enterprise technology spectrum of a corporate is usually based on a paradigm of disparate technology elements — from obsolete legacy units to the modern
          componentized systems of engagements. Diversity in this landscape means transformation requires diverse skillsets and a completely new strategy addressing
          multiple complexities.
        </div>
      </div>
      <div className='container mt-4'>
        <span className='pageMainHeading2 border-btm'>Transformation of Enterprise Technology</span>
        <div className='row mt-3'>
          <div className='col-12 col-lg-5'>
            <div className='row'>
              <div className='col-6'>
                <Fade left>
                  <img src={img1EP} className="img-fluid" alt="Idea" />
                  <div className='blueText pt-2'>Requirement Assessment</div>
                  <div className='redTextSmall'>X weeks</div>
                  <div className='blueText pt-2'>Feature Elaborations</div>
                  <div className='redTextSmall'>Y weeks</div>

                  <div className='blueText pt-2'>Integration Re-platforming Design</div>
                  <div className='redTextSmall'>Z weeks</div>
                </Fade>
              </div>
              <div className='col-6'>
                <Fade left>
                  <img src={img2EP} className="img-fluid" alt="Idea" />
                  <div className='blueText pt-2'>Integration implementation
                    Via APIfication</div>
                  <div className='redTextSmall'>To be estimated during discovery and integration design phase</div>
                </Fade>
              </div>
            </div>
          </div>
          <div className='col-12 col-lg-4 borderLeftBlueEnterprise mt-5 mt-lg-0'>
            <Fade right>
              <img src={img3EP} className="img-fluid" alt="Idea" />
            </Fade>
          </div>
          <div className='col-12 col-lg-3 mt-5 mt-lg-0'>
            <div className='blueText text-left' style={{ fontSize: "1rem" }}>Discovery</div>
            <span className='pageMainHeading2 border-btm text-left' style={{ fontSize: "0.9rem" }}>The Integration Landscape</span>
            <div className='mainContent'>
              1. Use Case<br></br>
              2. Sequence Flow<br></br>
              3. Migration roadmap<br></br>
              4. Actors in integration landscape<br></br>
              5. Collaboration Strategy
            </div>
          </div>
        </div>


        {/* Adde By AR */}
        {/* <div className='row mx-0 justify-content-between border-red mt-3 p-3'>
          <div className='col-11 col-md-2 text-center'>

            <div className='row justify-content-center justify-content-md-between mt-3'>
              <div className='col-8 mt-2'>
                <img src={Mobile} alt="" className='img-fluid' style={{ width: "88%" }} />
                <div className='imageTextRed'>Mobile</div>
              </div>
            </div>
          </div>

          <div className='col-11 col-md-2 text-center'>
            <div className='row justify-content-center justify-content-md-between mt-3'>
              <div className='col-8 mt-2'>
                <img src={Partnerships} className="img-fluid" alt="Partnerships" style={{ width: "83%" }} />
                <div className='imageTextRed'>Partnerships</div>
              </div>
            </div>
          </div>
          <div className='col-11 mt-3 col-md-2 text-center'>

            <div className='row justify-content-center justify-content-md-between'>
              <div className='col-8 mt-1'>
                <img src={Economy} alt="API Economy" className='img-fluid' style={{ width: "85%" }} />
                <div className='imageTextRed'>API Economy</div>
              </div>
            </div>
          </div>

          <div className='col-11 mt-3 col-md-2 text-center'>
            <div className='row justify-content-center justify-content-md-between'>
              <div className='col-8 text-center'>
                <img src={IOT} alt="IOT" className='text-center mt-4' style={{ width: "100%" }} />
                <div className='imageTextRed text-center text-md-center px-2'>IOT</div>
              </div>
            </div>
          </div>
          <div className='col-11  mt-3 col-md-auto text-center'>
            <div className='row justify-content-center'>
              <div className='col-12'>
                <img src={SAASOfferings} alt="SAAS Offerings" className='mt-4' style={{ width: "70%" }} />
                <div className='imageTextRed'>SAAS Offerings</div>
              </div>

            </div>
          </div>
        </div> */}
      </div>
    </div>





    // <div className="container-fluid pt-5 pb-5 px-0">
    //   <BreadCrumb text="Solutions " />
    //   <div className='container mt-4'>
    //     <h5 id='first-heading'><span className='heading' >ENTERPRISE SOFTWARE</span></h5>
    //     <div className='mt-4'>Innovation and Evolution are key factors that provide businesses the cutting edge to forge ahead of competition.
    //       We can guide you in leveraging the latest and best in technology. You can benefit from our experience in customised technology solutions, modernization of legacy systems, integration of diverse solutions.
    //       We can create enterprise solutions from scratch, enhance existing systems, conduct feasibility studies, and build “Proof of Concepts” to bring in transformation to your IT landscape.
    //       We delve deep into your business processes to create solutions that tackle organizational scenarios, be it content management, workflow, production process or internal communication. We translate your needs into solutions that are in tune with your industry specifics and unique business traits.</div>

    //     <h5 className='mt-4'><span className='heading ' >Current Status of Systems</span></h5>
    //     <div className='mt-4'>The Enterprise technology spectrum of a corporate is usually based on a paradigm of disparate technology elements — from obsolete legacy units to the modern componentized systems of engagements. Diversity in this landscape means transformation requires diverse skillsets and a completely new strategy addressing multiple complexities.</div>
    //     <h5 className=' mt-4 mb-4'><span className='heading '>Transformation of Enterprise Technology</span></h5>
    //     <div className='imgcontainer py-4 px-2 '>
    //       <img src={Integration}></img>
    //     </div>
    //   </div>


    // </div>
  )
}

export default EnterpriseSolutionPage;

