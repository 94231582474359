import Header from '../modules/HeaderModule/Header'
import SolutionReplaceModule from '../modules/SolutionReplaceModule/SolutionReplaceModule';
import Footer from '../modules/FooterModule/Footer'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const SolutionReplacePage = () => {
  const location = useLocation();

  useEffect(() => {
    document.title = 'Noveline - Solutions';
  }, []);

  return (
    <>
      <Header activeName={location.pathname} />
      <SolutionReplaceModule />
      <Footer scroll_position={100} />
    </>
  );
}

export default SolutionReplacePage;
