import React from 'react'
import Header from '../modules/HeaderModule/Header'
import Footer from '../modules/FooterModule/Footer'
import DeveloperUseOnly from '../modules/Trial/TrialModule';
import { useLocation } from 'react-router-dom';

const TrialPage = () => {
    const location = useLocation();
    return (
        <>
            <Header activeName={location.pathname} />
            <DeveloperUseOnly />
            <Footer scroll_position={100} />
        </>
    )
}

export default TrialPage