import React from 'react';
import BrassImg from '../../assets/images/BrassImg.png';
import TeaBlendsImg from '../../assets/images/TeaBlendsImg.png';
import './CaseStudyStyle.scss'
import { FaLongArrowAltRight } from 'react-icons/fa';
import BreadCrumb from '../BreadCrumb/BreadCrumb';
import { useNavigate } from "react-router-dom"

const CaseStudyModule = () => {
    const navigate = useNavigate();
    return (
        <>
            {/* <!-- Content Start From Here --> */}
            <div className="container-fluid mt-3 mt-md-2 mt-lg-0  pt-5 pb-5 px-0 ">{/*case-text*/}
                <BreadCrumb text="Case Studies" />
                <div className="container ps-3 pt-3 text-center">
                    <div className="row justify-content-center mx-auto mx-md-0 g-2 justify-content-center">
                        {/*card-container*/}
                        {/* --------------1------------ */}
                        <div className="CardTrans col-12 col-md-4 mt-4 mt-md-1 pt-4 pt-md-0 ">
                            <div className=" cards  h-100 ">
                                <div className='solublueBgImg casestudyImageBorderMain text-center mx-0 p-3'>
                                    <img src={TeaBlendsImg} className="card-img-top " style={{ width: "30%" }} alt="webapp" />
                                </div>
                                <div className="dataHover card-body mb-4 pt-0">
                                    <div className="cardMainHeading">Tea Blends</div>
                                    <div className="cardDescHeadings">Optimize Cost & Maintain Quality </div>
                                    <div className="cardSubContent">How did we save significant costs of Tea blends for a leading Tea manufacturer despite it being a non-linear multi-faceted problem?</div>
                                </div>
                                <div className='vBottom'>
                                    <div className="bottomButtonHover text-end readMoreSolHover pt-2 pb-2">
                                        <button onClick={() => navigate("/CaseStudy/Tea_blend")}>
                                            <div className='btm mx-1 row justify-content-end'>
                                                <div className='col-auto px-1'>
                                                    Read More
                                                </div>
                                                <div className='col-auto mx-0 iconHover3 p-0 mt-1'>
                                                    <FaLongArrowAltRight />
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* - -------------------------2-------------------- */}
                        <div className="CardTrans col-12 col-md-4 mt-4 mt-md-1 pt-4 pt-md-0">

                            <div className=" cards  h-100 ">
                                <div className='solublueBgImg casestudyImageBorderMain text-center mx-0 p-3'>
                                    <img src={BrassImg} className="card-img-top " style={{ width: "30%" }} alt="webapp" />
                                </div>
                                <div className="dataHover card-body mb-4 pt-0">
                                    <div className="cardMainHeading ">BRASS</div>
                                    <div className="cardDescHeadings">Centralised Management of Records</div>
                                    <div className="cardSubContent ">How was the challenge of inward and outward flow and accessibility of records in a marine defence organisation with central, sub-units and ships met?
                                    </div>
                                </div>
                                <div className='vBottom'>
                                    <div className="bottomButtonHover text-end readMoreSolHover pt-2 pb-2">
                                        <button onClick={() => navigate("/CaseStudy/Brass")}>
                                            <div className='btm mx-1 row justify-content-end'>
                                                <div className='col-auto px-1'>
                                                    Read More
                                                </div>
                                                <div className='col-auto mx-0 iconHover3  p-0 mt-1 '>
                                                    <FaLongArrowAltRight />
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CaseStudyModule;