import React from 'react';
// import teaBlend from '../../assets/images/teaBlend.png';
// import teaBlBrassManagement from '../../assets/images/BrassManagement.png';
import Solution1 from '../../assets/images/SolutionHome_1.png';
import Solution2 from '../../assets/images/SolutionHome_2.png';
import Solution3 from '../../assets/images/SolutionHome_3.png';
import Solution4 from '../../assets/images/SolutionHome_4.png';
import Solution5 from '../../assets/images/SolutionHome_5.png';
import Solution6 from '../../assets/images/SolutionHome_6.png';
// import './caseStudy.scss';
import BreadCrumb from '../BreadCrumb/BreadCrumb';
import { useNavigate } from "react-router-dom"
// import './solution.scss'
const SolutionReplaceModule = () => {
    const navigate = useNavigate();
    return (
        <>
            {/* <!-- Content Start From Here --> */}
            <div className="container-fluid mt-3  mt-md-2 mt-lg-0  pt-5 pb-5 px-0 ">{/*case-text*/}
                <BreadCrumb text="Solutions" />
                <div className="container-fluid ps-3 pt-3">
                    <div className="row mx-auto mx-sm-auto mx-md-0 mx-lg-0 row-cols-1 row-cols-md-4 g-2">{/*card-container*/}
                        <div className="col-12 col-lg-3 col-md-4 mt-lg-1 pt-lg-0 mt-md-1 pt-md-0 mt-4 pt-4 ">
                            <div className="CardTran card h-100 ">
                                <div className='blueBg text-center mx-0 p-3'>
                                    <img src={Solution1} className="card-img-top " style={{ width: "50%" }} alt="webapp" />
                                </div>
                                <div className="card-body ">
                                    <div className="cardMainHeading ">Web Applications</div>
                                    <div className="cardSubContent ">From discovery to launch we craft Web Applications that are tailored to fit your business by providing attention to detail through each step....
                                </div>
                                </div>
                                <div >
                                    <div className="text-end readMore p-3"><button onClick={() => navigate("/solution/webapp_page")}>Read More {'>'}</button></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 col-md-4  mt-1">
                            <div className="CardTran card h-100">
                                <div className='blueBg text-center mx-0 p-3'>
                                    <img src={Solution2} className="card-img-top" style={{ width: "70%" }} alt="Enterprise" />
                                </div>
                                <div className="card-body">
                                    <div className="cardMainHeading">Enterprise Software</div>
                                    <div className="cardSubContent">We create enterprise apps that
                                        are business-driven and user-focused, to increase profitability,
                                        efficiency and save costs....</div>
                                </div>
                                <div >
                                    <div className="text-end readMore p-3"><button onClick={() => navigate("/solution/enterprise_page")}>Read More {'>'}</button></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 col-md-4  mt-1">
                            <div className="CardTran card h-100">
                                <div className='blueBg text-center mx-0 p-3'>
                                    <img src={Solution3} className="card-img-top" style={{ width: "21%" }} alt="mobileapp" />
                                </div>
                                <div className="card-body">
                                    <div className="cardMainHeading">Mobile Applications</div>
                                    <div className="cardSubContent">Power driven functionalities with mesmerizing designs are the attributes we deliver for Mobile Apps....
                                    </div>
                                </div>
                                <div >
                                    <div className="text-end readMore p-3"><button onClick={() => navigate("/solution/mobileapp_page")}>Read More {'>'}</button></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 col-md-4 mt-1">
                            <div className="CardTran card h-100">
                                <div className='blueBg text-center mx-0 p-3'>
                                    <img src={Solution4} className="card-img-top" style={{ width: "59%" }} alt="software-outsourcing" />
                                </div>
                                <div className="card-body">
                                    <div className="cardMainHeading">Software Outsourcing</div>
                                    <div className="cardSubContent">A dedicated development centre with us is an engagement model with productivity, exclusivity of teams and integrity....</div>
                                </div>
                                <div >
                                    <div className="text-end readMore p-3"><button onClick={() => navigate("/solution/outsourcing_page")}>Read More {'>'}</button></div>
                                </div>
                            </div>
                        </div>

                        {/*card-container*/}
                        <div className="col-12 col-lg-3 col-md-4 mt-1 mt-lg-4">
                            <div className="CardTran card h-100">
                                <div className='blueBg text-center mx-0 p-3'>
                                    <img src={Solution5} className="card-img-top" style={{ width: "30%" }} alt="helpdesk" />
                                </div>
                                <div className="card-body">
                                    <div className="cardMainHeading">Software Support Help Desk</div>
                                    <div className="cardSubContent">We provide IT help desk services to companies for user and solution support of solutions that are on premise or on Cloud....</div>
                                </div>
                                <div >
                                    <div className="text-end readMore p-3"><button onClick={() => navigate("/solution/helpdesk_page")}>Read More {'>'}</button></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 col-md-4 mt-1 mt-lg-4">
                            <div className="CardTran card h-100">
                                <div className='blueBg text-center mx-0 p-3'>
                                    <img src={Solution6} className="card-img-top" style={{ width: "41%" }} alt="tracking" />
                                </div>
                                <div className="card-body ">
                                    <div className="cardMainHeading">Asset Management</div>
                                    <div className="cardSubContent">RFID based solution, to track and conduct Audit of button large number of heterogeneous assets, spread over buildings....</div>
                                </div>
                                <div >
                                    <div className="text-end readMore p-3"><button onClick={() => navigate("/solution/tracking_page")}>Read More {'>'}</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SolutionReplaceModule;