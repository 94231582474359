import React from 'react'
import BreadCrumb from '../BreadCrumb/BreadCrumb'
// import './solution_pages.scss';
import Web from '../../assets/images/OfferingsWeb_1.png';
import Existing from '../../assets/images/OfferingsWeb_2.png';
import BugFixing from '../../assets/images/Bug.png';
import Customapp from '../../assets/images/CustomApps.png'
import Frontend from '../../assets/images/UI_UX.png'
import Support from '../../assets/images/Support.png'
import Saas from '../../assets/images/SAAS-Offerings.png'
import Integration from '../../assets/images/operations-icon.png'
import Research from '../../assets/images/Research.png'
import Strategy from '../../assets/images/Strategy.png'
import Roadmap from '../../assets/images/Roadmap.png'
import SystemArchitecture from '../../assets/images/SystemArchitecture.png'
import Creative from '../../assets/images/Creative.png'
import Prototyping from '../../assets/images/Prototyping.png'
import AgileDevelopment from '../../assets/images/AgileDevelopment.png'
import Testing from '../../assets/images/Testing.png'
import Implementation from '../../assets/images/Implementation.png';
import { Fade } from 'react-reveal';

const webAppSolutionModule = () => {
  return (
    <>
      <div className="container-fluid mt-4 mt-sm-4 mt-md-2 mt-lg-0 pt-5 pb-5 px-0 ">
        <BreadCrumb text="Solutions" />
        <div className='container mt-4'>
          <div className='margin-top'>
            <span className='pageMainHeading border-btm mx-0 px-0'>WEB APPLICATIONS</span>
          </div>
        </div>
        <div className='container mt-4'>
          <div className='mainContent'>With long-standing experience across the entire paradigm of the software development lifecycle Noveline serves as a full stack vendor, undertaking end-to-end custom web
            application development projects for entrenched market leaders and emerging businesses with "Technology For The Growth of Customer Business" as the core guiding principle.
            Engineering solutions must be precise. Strategy for technology solutions needs understanding of the domain. Web applications need to work flawlessly every time, for every
            user, in myriad situations. Noveline Technologies integrates engineering strategy, understanding of the domain and brand values at the outset of your project in order to assess
            scope, technical requirements, approach, and feasibility. Doing this not only increases success rates, it positions your custom software to prove it's value year after year.</div>
        </div>
        <div className='container mt-4'>
          <div className='row'>
            <div className='col-12 col-lg-6 col-sm-12'>
              <div className='mainContentHeadingRed'>User-Focused Development</div>
              <div className='mainContent'>
                The best UX is built on designs that focus on users. Our team of UX developers
                translate designs into responsive front-end interfaces that provide an intuitive,
                engaging and performance oriented.
              </div>
            </div>
            <div className='col-12 col-lg-6 col-sm-12 mt-3 mt-lg-0'>
              <div className='mainContentHeadingRed'>Latest Technologies</div>
              <div className='mainContent'>
                We keep ourselves abreast of the technologies of the future. We build
                scalable and maintainable apps.
              </div>
            </div>
          </div>
          <div className='row mt-4'>
            <div className='col-12 col-lg-6 col-sm-12'>
              <div className='mainContentHeadingRed'>Flexible And Scalable</div>
              <div className='mainContent'>
                Whether it's provisioning for adaptability of code or preparing for subsequent
                phases, we're always ahead of the curve.
              </div>
            </div>
            <div className='col-12 col-lg-6 col-sm-12 mt-3 mt-lg-0'>
              <div className='mainContentHeadingRed'>Security And Ease Of Maintenance</div>
              <div className='mainContent'>
                Our code is uncluttered, secure, re-usable and extensible. We use technology frameworks that
                build web applications that are reliable and secure providing long term cost effectiveness.
              </div>
            </div>
          </div>
        </div>
        <div className='container mt-4'>
          <div className='pageMainHeading2 border-btm'>Offerings@Web</div>
          <Fade bottom>
            <div className="row card-container mt-4 justify-content-between mx-auto mx-sm-auto mx-md-0 mx-lg-0 row-cols-1 row-cols-md-4">{/*card-container*/}
              <div className="col-12 col-md-2 mt-1">
                <div className="card h-100" >
                  <img src={Web} className="card-img-top img-fluid mx-auto mt-3 " style={{ width: "30%" }} alt="offeringWeb_1" />
                  <div className="card-body">
                    <div className="cardMainHeading text-center mt-4 fontSolution">Website from Scratch</div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-2 mt-1">
                <div className="card h-100" >
                  <img src={Existing} className="card-img-top mx-auto mt-3 img-fluid" style={{ width: "30%" }} alt="OfferingsWeb_2" />
                  <div className="card-body">
                    <div className="cardMainHeading text-center mt-3 fontSolution">Existing Sites Development</div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-2 mt-1">
                <div className="card h-100" >
                  <img src={BugFixing} className="card-img-top mx-auto mt-3 img-fluid" style={{ width: "20%" }} alt="Bug" />
                  <div className="card-body">
                    <div className="cardMainHeading text-center  mt-3 fontSolution">Bug Fixing & Optimization</div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-2 mt-1">
                <div className="card h-100" >
                  <img src={Customapp} className="card-img-top mx-auto mt-3 img-fluid" style={{ width: "18%" }} alt="Custom-app" />
                  <div className="card-body">
                    <div className="cardMainHeading text-center  mt-3 fontSolution">Custom Apps</div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-2 mt-1">
                <div className="card h-100" >
                  <img src={Frontend} className="card-img-top img-fluid mx-auto mt-3" style={{ width: "18%" }} alt="Frontend" />
                  <div className="card-body">
                    <div className="cardMainHeading text-center mt-3 fontSolution">Frontend: UI/UX RWD</div>
                  </div>
                </div>
              </div>
              {/* ------------- */}
            </div>
            <div className="row card-container mt-0 mt-lg-4 justify-content-between mx-auto mx-sm-auto mx-md-0 mx-lg-0 row-cols-1 row-cols-md-4">
              <div className="col-12 col-md-2  mt-1">
                <div className="card h-100" >
                  <img src={Support} className="card-img-top img-fluid mx-auto mt-3" style={{ width: "30%" }} alt="Support" />
                  <div className="card-body">
                    <div className="cardMainHeading text-center mt-2 fontSolution">On-going Support & Maintenance</div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-2 mt-1">
                <div className="card h-100" >
                  <img src={Saas} className="card-img-top img-fluid mx-auto mt-4" style={{ width: "30%" }} alt="Saas" />
                  <div className="card-body">
                    <div className="cardMainHeading text-center mt-4 fontSolution">Saas</div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-2 mt-1">
                <div className="card h-100" >
                  <img src={Integration} className="card-img-top img-fluid mx-auto mt-4" style={{ width: "25%" }} alt="Integration-app" />
                  <div className="card-body">
                    <div className="cardMainHeading text-center mt-2 fontSolution">Integration & Migration
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-2 mt-1">
                <div className="card d-none" >
                </div>
              </div>
              <div className="col-12 col-md-2 mt-1 ">
                <div className="card d-none" >
                </div>
              </div>
            </div>
          </Fade>
        </div>
        <div className='container mt-4'>
          <div className='pageMainHeading2 border-btm'>The Process</div>
          <div className="row mx-0 mt-4 justify-content-center justify-content-md-between">
            <Fade right>
              <div className="col-12 col-md-auto mt-1 ProcessBorder">
                <div className='pageSolutionHeading3'>1</div>
                <div className='processData'>
                  <img src={Research} className="img-fluid mx-auto mb-1" style={{ width: "30%" }} alt="Research" />
                  <div className="cardMainHeading text-center mt-3 mb-1 fontSolution">User Research</div>
                </div>
              </div>
              <div className="col-12 col-md-auto mt-1 ProcessBorder " >
                <div className='pageSolutionHeading3 '>2</div>
                <div className='processData '>
                  <img src={Strategy} className="img-fluid mx-auto" style={{ width: "30%" }} alt="Strategy" />
                  <div className="cardMainHeading text-center mt-3 mb-1 fontSolution">Strategy</div>
                </div>
              </div>
              <div className="col-12 col-md-auto mt-1 ProcessBorder " >
                <div className='pageSolutionHeading3 '>3</div>
                <div className='processData '>
                  <img src={Roadmap} className="img-fluid mx-auto mb-1" style={{ width: "30%" }} alt="Roadmap" />
                  <div className="cardMainHeading text-center mt-3 mb-1 fontSolution">Roadmap</div>
                </div>
              </div>
              <div className="col-12 col-md-auto mt-1 ProcessBorder " >
                <div className='pageSolutionHeading3'>4</div>
                <div className='processData '>
                  <img src={SystemArchitecture} className="img-fluid mx-auto mb-1" style={{ width: "30%" }} alt="System Architecture" />
                  <div className="cardMainHeading text-center mt-3 mb-1 fontSolution">System Architecture</div>
                </div>
              </div>
              <div className="col-12 col-md-auto mt-1 ProcessBorder " >
                <div className='pageSolutionHeading3 '>5</div>
                <div className='processData '>
                  <img src={Creative} className="img-fluid mx-auto mb-2" style={{ width: "30%" }} alt="Creative" />
                  <div className="cardMainHeading text-center mt-3 mb-1 fontSolution">Creative</div>
                </div>
              </div>
              <div className="col-12 col-md-auto mt-1 ProcessBorder " >
                <div className='pageSolutionHeading3 '>6</div>
                <div className='processData '>
                  <img src={Prototyping} className="img-fluid mx-auto mb-1" style={{ width: "30%" }} alt="Prototyping" />
                  <div className="cardMainHeading text-center mt-3 mb-1 fontSolution">Prototyping</div>
                </div>
              </div>
              <div className="col-12 col-md-auto mt-1 ProcessBorder " >
                <div className='pageSolutionHeading3 '>7</div>
                <div className='processData '>
                  <img src={AgileDevelopment} className="img-fluid mx-auto mb-2" style={{ width: "30%" }} alt="Agile Development" />
                  <div className="cardMainHeading text-center mt-3 mb-1 fontSolution">Agile Development</div>
                </div>
              </div>
              <div className="col-12 col-md-auto mt-1 ProcessBorder " >
                <div className='pageSolutionHeading3'>8</div>
                <div className='processData '>
                  <img src={Testing} className="img-fluid mx-auto" style={{ width: "30%" }} alt="Testing" />
                  <div className="cardMainHeading text-center mt-3 mb-1 fontSolution">Testing</div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
        <div className='row mt-2 justify-content-center'>
          <Fade right>
            <div className='container col-auto p-2 bgSecondary text-center mt-3 mt-lg-2 px-2 '>
              <img src={Implementation} className="img-fluid mx-auto" style={{ width: "35%" }} alt="Implementation" />
              <div className="text-center mt-3 mb-1 text-white fontSolution">Implementation</div>
            </div>
          </Fade>
        </div>
      </div>
    </>
  )
}

export default webAppSolutionModule;