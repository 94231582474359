import React from 'react'
import BreadCrumb from '../BreadCrumb/BreadCrumb';
import './solution_pages.scss';
import HelpDeskImg from '../../assets/images/HelpDesk.png'
import Level1 from '../../assets/images/Level1.png'
import Level2 from '../../assets/images/Level2.png'
import Level3 from '../../assets/images/Level3.png'
import { Fade, Bounce } from 'react-reveal';

const HelpDeskSolutionPage = () => {
  return (
    <div className="container-fluid mt-4 mt-sm-4 mt-md-2 mt-lg-0 pt-5 pb-5 px-0 ">
      <BreadCrumb text="Solutions" />
      <div className='container mt-4'>
        <div className='margin-top'>
          <span className='pageMainHeading border-btm mx-0 px-0'>HELP DESK OUTSOURCING SERVICES</span>
        </div>
      </div>
      <div className='container mt-4'>
        <div className='mainContent'>Depending upon the complexity of the support request we can assign the service ticket to the team segment that can handle it.</div>
        <div className='mt-3'>
          <img src={HelpDeskImg} className="img-fluid" style={{ width: "80%" }} alt="App Development"></img>
        </div>
      </div>
      <div className='container mt-4'>
        <span className='pageMainHeading2 border-btm'>Current Status of Systems</span>
        <div className='mainContent mt-1'>Be it an Enterprise technology solution, Cloud based service, or a mobile application, we provide IT help desk services to companies that need to outsource user and solution
          support. We can help you with tech support offerings to your customers and with corporate applications and systems that your employees use. Our support can assist with a
          range of issues from software activation up to trouble shooting.</div>
        <div className='mainContent mt-2'>Tech support teams provide this service through a mix of phone, live chat, email and video call. You don’t need to hire and train support teams and yet be reassured that the
          software users are taken care of.</div>
      </div>
      <div className='container justify-content-center mt-4'>
        <span className='pageMainHeading2 border-btm'>Levels of Support</span>
        {/* -------level 1----- */}
        <Fade bottom cascade>
          <div className='container  mx-0 row mt-2 p-3 borderStyleSol' >
            <div className='col-5 col-lg-1 text-center col-md-2 pageSolutionHeading2 col-lg-1 px-0 '>
              level 1
            </div>
            <div className='col-7 col-lg-2 col-md-4 borderRightSol col-lg-2 image ps-2'>
              <img src={Level1} style={{ width: "100%" }} />
            </div>
            <div className='col-12 col-md-6 borderTopSol mt-1 mt-md-0 mb-3 mainContentLevel col-lg-9'>
              <div className='solutionContentHeading mt-2 mt-lg-0'>L1</div>
              Our help desk team members at L1 level can reply to questions about the software usage, provide instructions and solve minor issues, such as software reactivation license registration and provide user guides, FAQs, and support articles.
            </div>
          </div>
          {/* ------------ */}
          {/* -------level 2----- */}
          <div className='container  mx-0 row border border-danger mt-2 p-3 borderStyleSol' >
            <div className='col-5 col-lg-1 text-center col-md-2 pageSolutionHeading2 col-lg-1 px-0 '>
              level 2
            </div>
            <div className='col-7 col-md-4 borderRightSol col-lg-2 image ps-2'>
              <img src={Level2} style={{ width: "100%" }} />
            </div>
            <div className='col-12 col-md-6 borderTopSol mt-1 mt-md-0 mainContentLevel  col-lg-9'>
              <div className='solutionContentHeading mt-2 mt-lg-0'>L2</div>
              Resolution of software installation, compatibility issues, create logs that help identify causes of issues and fix them if possible.
            </div>
          </div>
          <div className='container  mx-0 row border border-danger mt-2 p-3 borderStyleSol' >
            <div className='col-5 col-lg-1 text-center col-md-2 pageSolutionHeading2 col-lg-1 px-0 '>
              level 3
            </div>
            <div className='col-7 col-md-4 borderRightSol col-lg-2 image ps-2'>
              <img src={Level3} style={{ width: "100%" }} />
            </div>
            <div className='col-12 col-md-6 borderTopSol mt-1 mb-3 mt-lg-0 pb-3  mt-md-0 mainContentLevel  col-lg-9'>
              <div className='solutionContentHeading mt-2 mt-lg-0'>L3</div>
              We offer L3 support as an option. It includes tackling complex issues. Software engineers can get involved and defects uncovered by L1 and L2 agents can be fixed.
            </div>
          </div>
        </Fade>
        {/* ------------ */}
      </div>
    </div>
  )
}

export default HelpDeskSolutionPage;