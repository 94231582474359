import React from 'react'
import BreadCrumb from '../BreadCrumb/BreadCrumb'
import './TeaBlendStyle.scss'
import Tea_blend from '../../assets/images/TeaBlending.png'
import Grain from '../../assets/images/RawMaterial.png'
import Inventory from '../../assets/images/Inventory.png'
import Quality from '../../assets/images/Quality.png'
import Rules from '../../assets/images/Rules.png'
import Cost from '../../assets/images/CostSaving.png'
import User from '../../assets/images/UserInput.png'
import Service from '../../assets/images/ServiceStockDump.png'
import Process from '../../assets/images/ProcessExcludeFilter.png'
import Output from '../../assets/images/OutputBlends.png'
import Arrow from '../../assets/images/swirl-arrow-right-icon.png'
import { Fade, Bounce } from 'react-reveal';

const TeaBlendModule = () => {
  return (
    <>
      <div className="container-fluid mt-4 mt-sm-4 mt-md-2 mt-lg-0 pt-5 pb-5 px-0 ">{/*case-text*/}
        <BreadCrumb text="Case Studies" />
        <div className='container mt-4'>
          <div className='margin-top'>
            <span className='pageMainHeading border-btm mx-0 px-0'>TEA BLENDING OPTIMIZATION</span>
          </div>
        </div>
        <div className='container mt-4'>
          <div className='row mx-0 px-0 blueBg'>
            <div className='col-12 col-lg-2 text-center'>
              <div className='mt-3'>
                <img src={Tea_blend} className="bg img-width border-right-white my-4 img-fluid" alt="" />
              </div>
            </div>
            <div className='col-12 col-lg-10 border-white p-4 p-lg-3 margin'>
              <div className='aboutHeading'>About the Client</div>
              <div className='aboutContent mt-1 pe-5'>
                The client is a large MNC who is among the leaders in the FMCG Industry. The tea business is an important part of its FMCG business It has multiple tea brands and many varieties of tea. The demand for its tea blends runs into more than 50,000 tonnes in India (21.2 share in 2018) where the solution was meant to be implemented. Maintaining the standardization of various aspects of the tea blends and yet optimizing the cost is complex and challenging.
              </div>
            </div>
          </div>
        </div>
        <div className='container mt-4'>
          <div className='mainContentHeadingRed'>Industry</div>
          <div className='mainContent'>A mind-boggling 1 million cups of tea are drunk every minute of every day – making tea the world’s 2nd most consumed beverage in the world, after water.
            Tea is more than just a product – it touches so many people, it has a heritage, offers opportunities, and is, indeed, a way of life. While formulating new tea recipes,
            sustainable sourcing is as fundamental a consideration, in blend development as is raw material cost or its seasonality.</div>
          <div className='mainContent mt-2'>The Indian Tea Industry has an important and special place in the Indian economy. Tea is India's primary beverage, with almost 85% of total households in the country
            consuming tea. India is the world's largest producer and consumer of tea, with India accounting for 27% of the world tea production. Goldstein Research analyst forecast
            that the India tea industry outlook is set to reach USD 1 billion by 2025, growing at a CAGR of 5.8% over the forecast period (2017-2025).</div>
        </div>
        <div className='container mt-4'>
          <div className='mainContentHeadingRed'>Business Challenge</div>
          <div className='mainContent'>How to create a tea blend from a wide range of raw material, in the inventory which is inherently multi-characteristic and create tea blends that adhere to a variety of defined
            quality score parameters, with zero violation of rules of formulation and achieve cost saving.</div>
          <div className='row mx-0 justify-content-between border-red mt-3 p-3'>
            <Fade left cascade>
              <div className='col-11 col-md-2 text-center'>
                <div className='row justify-content-center justify-content-md-between mt-3'>
                  <div className='col-8 mt-2'>
                    <img src={Grain} alt="" className='img-fluid' style={{ width: "88%" }} />
                    <div className='imageTextRed'>Raw Material</div>
                  </div>
                  <div className='col-4 d-none d-md-block'>
                    <img src={Arrow} className="mt-5" alt="" style={{ width: "200%" }} />
                  </div>
                </div>
              </div>
              <div className='col-11 col-md-2 text-center'>
                <div className='row justify-content-center justify-content-md-between mt-3'>
                  <div className='col-8 mt-2'>
                    <img src={Inventory} className="img-fluid" alt="Inventory" style={{ width: "83%" }} />
                    <div className='imageTextRed'>Inventory</div>
                  </div>
                  <div className='col-4 d-none d-md-block'>
                    <img src={Arrow} className="mt-5" alt="" style={{ width: "200%" }} />
                  </div>
                </div>
              </div>
              <div className='col-11 mt-3 col-md-2 text-center'>
                <div className='row justify-content-center justify-content-md-between'>
                  <div className='col-8 mt-1'>
                    <img src={Quality} alt="" className='img-fluid' style={{ width: "85%" }} />
                    <div className='imageTextRed'>Quality</div>
                  </div>
                  <div className='col-4 d-none d-md-block'>
                    <img src={Arrow} className="mt-5" alt="" style={{ width: "200%" }} />
                  </div>
                </div>
              </div>
              <div className='col-11 mt-3 col-md-2 text-center'>
                <div className='row justify-content-center justify-content-md-between'>
                  <div className='col-8 text-center'>
                    <img src={Rules} alt="rules" className='text-center mt-4' style={{ width: "100%" }} />
                    <div className='imageTextRed text-center text-md-center px-2'>Rules</div>
                  </div>
                  <div className='col-4 d-none d-md-block'>
                    <img src={Arrow} className="mt-5" alt="" style={{ width: "200%" }} />
                  </div>
                </div>
              </div>
              <div className='col-11  mt-3 col-md-auto text-center'>
                <div className='row justify-content-center'>
                  <div className='col-12'>
                    <img src={Cost} alt="" className='mt-4' style={{ width: "70%" }} />
                    <div className='imageTextRed'>Cost Saving</div>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
        <div className='container mt-4'>
          <div className='mainContentHeadingRed'>Objectives</div>
          <div className='mainContent'>- The median score of each quality parameter of every lot of 'tea blend', must be within the prescribed range.</div>
          <div className='container-fluid mt-3 mx-0 px-0'>
            <table className="table table-bordered border-red text-center">
              <thead className='bg-red'>
                <tr>
                  <th scope="col" className='text-left table-width'>Grading Parameters</th>{/*added table-width external css class in scss*/}
                  <th scope="col">Minimum</th>
                  <th scope="col">Maximum</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className='text-left'>Brightness</td>
                  <td >XX.XX*</td>
                  <td>XX.XX</td>
                </tr>
                <tr>
                  <td className='text-left'>Color</td>
                  <td>XX.XX</td>
                  <td>XX.XX</td>
                </tr>
                <tr>
                  <td className='text-left'>Softness</td>
                  <td>XX.XX</td>
                  <td>XX.XX</td>
                </tr>
                <tr>
                  <td className='text-left'>Flavour</td>
                  <td>XX.XX</td>
                  <td>XX.XX</td>
                </tr>
              </tbody>
            </table>
            <div className='noteSmallText text-right margin-bottom'>
              <span>*some number</span>
            </div>
          </div>
          <div className='mainContent'>- Each characteristic of every blend must conform to all minimum and maximum percentage of blend quantity, as prescribed in the Blend Formulation Document of the
            R&D department.</div>
          <div className='mainContent'>- Any characteristic that is not permitted for a particular blend must result in the batch of raw material being excluded.</div>
        </div>
        <div className='container mt-4'>
          <div className='mainContentHeadingRed'>Restrictions</div>
          <div className='mainContent'>- Availability of stock and its nature at the time of blend creation.</div>
          <div className='mainContent'>- Splitting of bags of the raw material are to be minimized to the extent possible.</div>
        </div>
        <div className='container mt-4'>
          <div className='mainContentHeadingRed'>Optimization Objectives</div>
          <div className='mainContent'>- Create blends from the raw material in inventory to get the least cost per kg.</div>
          <div className='mainContent'>- Spread the cost benefit so as to avoid overconcentration of lesser cost in one or more blends.</div>
          <div className='mainContent'>- Fulfil maximum demand as per the demand plan.</div>
        </div>
        <div className='container mt-4'>
          <div className='mainContentHeadingRed'>Blend Simulator Solution</div>
          <div className='mainContent'>We firstly investigated the challenges in blending in the tea industry based on meeting with members of the R&D, Procurement team. Subsequently, the team visited the
            Kolkata tea centre of the company to meet the tea blenders and tasters and finally a visit to the factory. After studying the challenges and brainstorming on various
            approaches, we proposed a multi-objective model that would empower the blenders to create blend lots that is an optimised outcome. The solution, has resulted in the
            company being able to save money of a huge magnitude on a weekly basis. In the next phase, Price and Usage Trend analytic tools were added to the solution.</div>
        </div>
        <div className='container border-red justify-content-center mt-4'>

          <div className='row  '>
            <div className='col-11  m-auto col-md-4 text-center px-0 mt-4'>
              <img src={User} alt="user" className='img-fluid p-2 blendImg' />
              <div className='imageTextRed py-2'>User Input Demand Plan</div>
              <div className='text-center bg-red p-2 py-md-3 mb-0'>
                Blend names, Total quantity, Lot size.
              </div>
            </div>
            <div className='col-11 m-auto col-md-4 text-center px-0 pt-1 mt-4'>
              <img src={Service} alt="user" style={{ height: '146px' }} className='img-fluid p-2 blendImg' />
              <div className='imageTextRed'>Service Stock Dump</div>
              <div className='text-center bg-red  p-2 py-md-3'>
                Blend names, Total quantity, Lot size.
              </div>
            </div>
            <div className='col-11 m-auto col-md-4 text-center px-0 '>
              <img src={Process} alt="user" style={{ height: '134px' }} className='img-fluid p-2 blendImg ' />
              <div className='imageTextRed mt-3'>Process Excluded Filter</div>
              <div className='text-center bg-red p-2 py-md-3'>
                Blend names, Total quantity, Lot size.
              </div>
            </div>
          </div>

          <div />
        </div>
        {/* Page Added by ANAGHA END */}

        <div className='container text-center justify-content-center mt-4'>
          <div className='mt-5'>
            <img src={Output} alt="" style={{ width: "600px" }} className='img-fluid' />
          </div>
        </div>
      </div>
    </>
  )
}

export default TeaBlendModule