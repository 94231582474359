import Header from '../modules/HeaderModule/Header'
import MainContentModule from '../modules/MainContentModule/MainContentModule'
import Footer from '../modules/FooterModule/Footer'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const HomePage = () => {
  const location = useLocation();
  
  useEffect(() => {
    document.title = 'Noveline - Home';
  }, []);

  return (
    <>
      <Header activeName={location.pathname} />
      <MainContentModule />
      <Footer scroll_position={100} />
    </>
  );
}

export default HomePage;
