import React from 'react';
import teaBlend from '../../assets/images/teaBlend.png';
import teaBlBrassManagement from '../../assets/images/BrassManagement.png';
import './caseStudyReplace.scss';
import BreadCrumb from '../BreadCrumb/BreadCrumb';
import { useNavigate } from "react-router-dom"

const CaseStudyReplace = () => {
    const navigate = useNavigate();
    return (
        <>
            {/* <!-- Content Start From Here --> */}
            <div className="container-fluid mt-3 mt-sm-4 mt-md-2 mt-lg-0 pt-5 pb-5 px-0">
                <BreadCrumb text="Case Studies" />
                <div className="container-fluid ps-3 pt-3 ">
                    <div className="row mx-auto mx-sm-auto mx-md-0 mx-lg-0 row-cols-1 row-cols-md-4 g-4">
                        <div className="col-12 mt-5 mt-lg-0 mt-md-0 col-md-4 col-lg-3">
                            <div className="CardTran card h-100">
                                <img src={teaBlend} className="card-img-top" alt="teaBlend" />
                                <div className="card-body">
                                    <div className="cardMainHeading">Tea Blends</div>
                                    <div className="cardDescHeading">Optimize Cost & Maintain Quality </div>
                                    <div className="cardSubContent pb-2">How did we save significant costs of Tea blends
                                        for a leading Tea manufacturer despite it being a non-linear multi-faceted problem?</div>
                                </div>
                                <div >
                                    <div className="text-end readMore p-3"><button onClick={() => navigate("/CaseStudy/Tea_blend")}>Read More {'>'}</button></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 mt-1 col-md-4 mt-lg-0">
                            <div className="CardTran card h-100">
                                <img src={teaBlBrassManagement} className="card-img-top" alt="teaBlBrassManagement" />
                                <div className="card-body">
                                    <div className="cardMainHeading">BRASS</div>
                                    <div className="cardDescHeading">Centralised Management of Records</div>
                                    <div className="cardSubContent pb-2">How was the challenge of inward and outward flow and
                                        accessibility of records in a marine defence organisation with central, sub-units
                                        and ships met?</div>
                                </div>
                                <div >
                                    <div className="text-end readMore p-3"><button onClick={() => navigate("/CaseStudy/Brass")}>Read More {'>'}</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CaseStudyReplace;