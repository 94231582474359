import React from 'react'
import BreadCrumb from '../BreadCrumb/BreadCrumb'
import Image1 from '../../profile_images/Dr.NirmaJain.jpg'
import Image2 from '../../profile_images/DilipVarma.jpg'
import Image3 from '../../profile_images/Suoprakash.jpg'
import Image4 from '../../profile_images/VinodTakarkhedesir.jpeg'
import Image5 from '../../profile_images/ShrutiNayak.jpg'
import './TeamStyle.scss'
const team = () => {
    return (
        <>
            <div className="container-fluid mt-4 mt-sm-4 mt-md-2 mt-lg-0  pt-5 pb-5 px-0">
                <BreadCrumb text="Team" />
                <div className='container mt-4'>
                    <div className='mx-0'>
                        <span className='pageMainHeading mt-4 border-btm ml-4 mx-0 px-0'>CORE TEAM</span>
                    </div>
                    <div className='row justify-content-center mt-3'>
                        <div className='col-10 col-md-3 col-lg-2'>
                            <div className='row justify-content-center'>
                                <img src={Image1} className="profile-width col-8 img-fluid borderBlue p-3" alt="img1" />
                                <div className='name col-10 mt-3 mb-2 text-center'>Dr. Nirmal Jain</div>
                            </div>
                        </div>
                        <div className='col-11 col-md-9 col-lg-10 ml-0'>
                            <div className='bg p-3'>
                                <div className='teamHeading1'>Dr. Nirmal Jain - Chief Business Strategist </div>
                                <div className='teamHeading2'>Phd in CS from University of Hawai, Business Management from Havard, MTech IIT Delhi, BTech IIT Mumbai.</div>
                                <div className='teamHeading2'>Past Positions: MD at Tata Infotech, Sr. V.P. Asia Pacific at TCS, CEO ISG Novasoft. </div>
                            </div>
                            <div className='mt-2'>
                                <span className='teamHeadingBlue'>Experience:</span>
                            </div>
                            <div className='mainContent'>
                                Dr. Jain spent 25 years with Tata Group Companies. At TCS as Sr. V.P., he was responsible for all the European and Asia/Pacific business, and part of the Strategic Management team. At Tata Infotech as Managing Director & CEO, he was responsible for making the company a leading Systems Integration Company in India. He was responsible for tripling its revenues over 5 years.
                            </div>
                            <div className='mt-2'>
                                <span className='teamHeadingBlue'>Interests:</span>
                            </div>
                            <div className='mainContent'>
                                His interests are Business Strategy and Mentoring.
                            </div>
                            <div className='mt-2'>
                                <span className='teamHeadingBlue'>Role:</span>
                            </div>
                            <div className='mainContent'>
                                Drives the business strategy and is an integral part of all major initiatives.
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-center mt-5'>
                        <div className='col-10 col-md-3 col-lg-2'>
                            <div className='row justify-content-center'>
                                <img src={Image2} className="profile-width col-8 img-fluid border border-danger p-3" alt="img1" />
                                <div className='name2 col-10 mb-2 mt-3 text-center'>Dilip Varma </div>
                            </div>
                        </div>
                        <div className='col-11 col-md-9 col-lg-10 ml-0'>
                            <div className='bg-red p-3'>
                                <div className='teamHeading1'>Dilip Varma - Founder & Project Head  </div>
                                <div className='teamHeading2'>Masters in Computer Applications.</div>
                                <div className='teamHeading2'>Past Positions: Technical Architect at Hypertrends Inc, Project Mangement at Cambridesoft Inc, CTO at Sphinx Communications.  </div>
                            </div>
                            <div className='mt-2'>
                                <span className='teamHeadingRed'> Experience:</span>
                            </div>
                            <div className='mainContent'>
                                Dilip Varma has 20 years experience in the field of Information Technology. He has extensive experience as a CTO and Technical Architect especially in Microsoft Technologies. He has worked in teams in Enterprise Software Applications for MNC clients. Dilip has headed new technical initiatives. He was responsible for guiding and expanding the IT in his tenure at Sphinx.

                            </div>
                            <div className='mt-2'>
                                <span className='teamHeadingRed'>Interests:</span>
                            </div>
                            <div className='mainContent'>
                                His interests are Fintech and Edtech.
                            </div>
                            <div className='mt-2'>
                                <span className='teamHeadingRed'>Role:</span>
                            </div>
                            <div className='mainContent'>
                                Dilip Varma founded Noveline Technologies Pvt. Ltd. He heads the Project Division and is responsible for business expansion and technical collaboration.
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-center mt-5'>
                        <div className='col-10 col-md-3 col-lg-2'>
                            <div className='row justify-content-center '>
                                <img src={Image3} className="profile-width col-8 img-fluid borderBlue p-3 imgHeight" alt="img1" />
                                <div className='name col-10 mb-2 mt-3 text-center '>Suprokash Goswami</div>
                            </div>
                        </div>

                        <div className='col-11 col-md-9 col-lg-10 ml-0'>
                            <div className='bg  p-3'>
                                <div className='teamHeading1'>Suprokash Goswami - Chief Technology Officer</div>
                                <div className='teamHeading2'>Post Graduate in IT from Symbiosis Pune. </div>
                                <div className='teamHeading2'>Past Positions: Amazon, Net App.</div>
                            </div>
                            <div className='mt-2'>
                                <span className='teamHeadingBlue'> Experience:</span>
                            </div>
                            <div className='mainContent'>
                                Suprokash Goswami has 20 years experience. He has worked as CTO at Tapped Inc. At Amazon, he was the QA Manager for Kindle. Suprokash was the Transition Architect at NetApp.

                            </div>
                            <div className='mt-2'>
                                <span className='teamHeadingBlue'>Interests:</span>
                            </div>
                            <div className='mainContent'>
                                His interests are Product Engineering.
                            </div>
                            <div className='mt-2'>
                                <span className='teamHeadingBlue'>Role:</span>
                            </div>
                            <div className='mainContent'>
                                Suprokash functions as the Chief Technological Officer at Noveline.
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-center mt-5'>
                        <div className='col-10 col-md-3 col-lg-2'>
                            <div className='row justify-content-center'>
                                <img src={Image4} className="profile-width col-8 img-fluid border border-danger p-3" alt="img1" />
                                <div className='name2 col-10 mt-3 mb-2 text-center'>Vinod Takarkhede</div>
                            </div>
                        </div>
                        <div className='col-11 col-md-9 col-lg-10 ml-0'>
                            <div className='bg-red p-3'>
                                <div className='teamHeading1'>Vinod Takarkhede - Tech lead </div>
                                <div className='teamHeading2'>Post Graduate in Computer Science  </div>
                                <div className='teamHeading2'>Past Positions: EZ-Data (now: Ebix), Premier Technologies, Infospectrum (now: Persistant Systems) </div>
                            </div>
                            <div className='mt-2'>
                                <span className='teamHeadingRed'> Experience:</span>
                            </div>
                            <div className='mainContent'>
                                Vinod has 23 years experience in IT. He has a wide range of experience in C, C++, MFC, AIX, Solaris, Linux, PHP, MySQL, Hybrid Mobile Apps. He also has domain experience in Network Security, VTMS and Insurance.

                            </div>
                            <div className='mt-2'>
                                <span className='teamHeadingRed'>Interests:</span>
                            </div>
                            <div className='mainContent'>
                                His interests are building robust scalable frameworks.
                            </div>
                            <div className='mt-2'>
                                <span className='teamHeadingRed'>Role:</span>
                            </div>
                            <div className='mainContent'>
                                Responsible for technical guidance and mentoring of mid-level technical team.
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-center mt-5'>
                        <div className='col-10 col-md-3 col-lg-2'>
                            <div className='row justify-content-center'>
                                <img src={Image5} className="profile-width col-8 img-fluid borderBlue p-3" alt="img1" />
                                <div className='name mt-3 col-10 mb-2 text-center'>Shruti Nayak</div>
                            </div>
                        </div>
                        <div className='col-11 col-md-9 col-lg-10 ml-0'>
                            <div className='bg p-3'>
                                <div className='teamHeading1'>Shruti Nayak - Project Management</div>
                                <div className='teamHeading2'>Masters in Information Technology</div>
                                <div className='teamHeading2'>Past Positions: Senior Developer at Noveline, Lecturer in Mumbai University. </div>
                            </div>
                            <div className='mt-2'>
                                <span className='teamHeadingBlue'> Experience:</span>
                            </div>
                            <div className='mainContent'>
                                Shruti has grown from a Jr. Developer to heading the project management team at Noveline. She has experience in handling projects and interacting with the Tech team as well as clients.

                            </div>
                            <div className='mt-2'>
                                <span className='teamHeadingBlue'>Interests:</span>
                            </div>
                            <div className='mainContent'>
                                Her interests are Enterprise Software.
                            </div>
                            <div className='mt-2'>
                                <span className='teamHeadingBlue'>Role:</span>
                            </div>
                            <div className='mainContent'>
                                Shruti Nayak heads the Project Management team.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default team