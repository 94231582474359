import React  from "react";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import './App.scss';
import HomePage from "./Pages/IndexPage";
import CaseStudyReplacePage from './Pages/CaseStudyReplacePage.js'
import EnterprisePage from "./Pages/EnterprisePage.js";
import TeaBlendPage from './Pages/TeaBlendPage.js';
import BrassPage from "./Pages/BrassPage";
import MobilePage from "./Pages/MobileAppPage.js";
import OutsourcingPage from "./Pages/OutsourcingPage.js";
import HelpDeskPage from "./Pages/HelpDeskPage.js";
import TrackingSolutionPage from "./Pages/TrackingsolutionPage.js";
import WebAppPage from "./Pages/WebAppPage.js";
import SolutionReplacePage from "./Pages/SolutionReplacePages.js";
import ErrorPage from './Pages/ErrorPages.js';
import MainTeamPage from "./Pages/MainTeamPage.js";
import TeamsPage from "./Pages/TeamPage";
import TrialPage from "./Pages/TrialPages";
import SolutionsPage from './Pages/SolutionsPage'
import CaseStudyPage from "./Pages/CaseStudyPage";
import ScrollToTop from './modules/Scroller/ScrollToTop'
function App() {
  // const [showButton, setShowButton] = useState(false);

  // useEffect(() => {
  //   window.addEventListener("scroll", () => {
  //     if (window.pageYOffset > 300) {
  //       setShowButton(true);
  //     } else {
  //       setShowButton(false);
  //     }
  //   });
  // }, []);

  // // This function will scroll the window to the top 
  // const scrollToTop = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: 'smooth' // for smoothly scrolling
  //   });
  // };

  return (
    <>
    <ScrollToTop/>
      <BrowserRouter>
        <Routes>
          <Route index element={<><HomePage /></>} />
          <Route path="/" element={<><HomePage /></>} />
          <Route path="/casestudyReplace" element={<><CaseStudyReplacePage /></>} />
          <Route path="/SolutionReplace" element={<><SolutionReplacePage /></>} />
          <Route path="/CaseStudy/Tea_blend" element={<><TeaBlendPage /></>} />
          <Route path="/CaseStudy/Brass" element={<><BrassPage /></>} />
          <Route path="/solution/webapp_page" element={<><WebAppPage /></>} />
          <Route path="/solution/enterprise_page" element={<><EnterprisePage /></>} />
          <Route path="/solution/mobileapp_page" element={<><MobilePage /></>} />
          <Route path="/solution/outsourcing_page" element={<><OutsourcingPage /></>} />
          <Route path="/solution/helpdesk_page" element={<><HelpDeskPage /></>} />
          <Route path="/solution/tracking_page" element={<><TrackingSolutionPage /></>} />
          <Route path="/team" element={<><TeamsPage /></>} />
          <Route path="/mainteam" element={<><MainTeamPage /></>} />
          <Route path="/trial" element={<><TrialPage /></>} />
          <Route path="*" element={<><ErrorPage /></>} />
          <Route path="/solution" element={<><SolutionsPage /></>} />
          <Route path="/CaseStudy" element={<><CaseStudyPage /></>} />

        </Routes>
      </BrowserRouter>
      {/* {showButton && (
        <button onClick={scrollToTop} className="back-to-top">
          &#8679;
        </button>
      )} */}
    </>
  );
}

export default App;
