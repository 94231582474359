import React from 'react'
import Header from '../modules/HeaderModule/Header'
import Footer from '../modules/FooterModule/Footer'
import TrackingSolutionModule from '../modules/SolutionsPagesModule/TrackingSolutionModule'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const TrackingsolutionPage = () => {
  const location = useLocation();
  
  useEffect(() => {
    document.title = 'Noveline - Asset Tracking';
  }, []);
  
  return (
    <>
      <Header activeName={location.pathname} />
      <TrackingSolutionModule />
      <Footer scroll_position={100} />
    </>
  )
}

export default TrackingsolutionPage