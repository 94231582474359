import React from 'react'
import BreadCrumb from '../BreadCrumb/BreadCrumb';
import './solution_pages.scss';
import NovelineManaged from '../../assets/images/NovelineManaged.png';
import Teamicon from '../../assets/images/team-icon.png'
const OutsourcingSolutionModule = () => {
    return (

        <div className="container-fluid mt-4 mt-sm-4 mt-md-2 mt-lg-0 pt-5 pb-5 px-0 ">
            <BreadCrumb text="Solutions" />
            <div className='container mt-4'>
                <div className='margin-top'>
                    <span className='pageMainHeading border-btm mx-0 px-0'>END-TO-END SOFTWARE OUTSOURCING SOLUTIONS</span>
                </div>
            </div>
            <div className='container mt-4'>
                <div className='mainContent'>We provide companies with access to expert developers boasting a range of tech skills from developers to UX designers. And they can start working on a project right away.</div>
                <div className='mainContent mt-2'>From customization of existing platforms to full-cycle custom software development, Noveline is an experienced development partner to deliver reliable and cost-effective
                    custom software solutions.</div>
            </div>
            <div className='container mt-4'>
                <span className='pageMainHeading2 border-btm'>Engagement Models</span>
                <div>
                    <div className='row mt-2 justify-content-center h-100'>
                        <div className='col-12 col-md-4 mt-1'>
                            <div className='card border border-danger h-100 borderRadius'>
                                <div className='card-header heading1 borderRadius'>
                                    <span className='mt-2'>Team Augmentation</span>
                                </div>
                                <div className='card-body mainContent'>
                                    Our software engineers work together with your internal team on your projects. These developers join your existing staff, often working from your offices until project completion.
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-4 mt-1'>
                            <div className='card border border-danger h-100 borderRadius'>
                                <div className='card-header heading1  borderRadius'>
                                    <span className='mt-2'>Delivery team</span>
                                </div>
                                <div className='card-body mainContent'>
                                    Provides resources which directly oversees the whole development project. This option provides a management structure with hands-on tracking and monitoring of the tasks assigned.
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-4 mt-1'>
                            <div className='card border border-danger h-100 borderRadius'>
                                <div className='card-header heading1 borderRadius'>
                                    <span className='mt-2'>Project-based</span>
                                </div>
                                <div className='card-body mainContent'>
                                    Noveline Technologies overseas the entire development process: from establishing the specs, to allocating workflow, setting timescales and quality testing.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mt-4'>
                <span className='pageMainHeading2 border-btm'>Noveline Managed</span>
                <div className='row justify-content-center '>
                    <div className='col-11 col-md-10'>
                        <div className='mainContent mt-1'>We offer full-fledged and efficiently managed dedicated teams for complete coverage of all your technology driven needs. You can determine the
                            degree of monitoring and involvement that is required and we will ensure transparency of a high order in your project.</div>
                        <div className='mainContent mt-2'>An expert autonomous team, typically composed of a Project Manager, Software engineers, QA engineers and other roles, capable of rapidly and
                            efficiently deliver end-to-end technology solutions. Management is conducted jointly by Project Manager and Client's Product Owner. The whole
                            team interacts as often as needed in order to ensure optimal results at delivery time.</div>

                    </div>
                    <div className='col-10 col-md-2 mt-2 img-border text-center'><img className='py-3 flex-row img-fluid' style={{ width: "50%" }} src={NovelineManaged}></img></div>
                </div>
            </div>

            <div className='container mt-4'>
                <span className='pageMainHeading2 border-btm'>Client Managed</span>
                <div className='row justify-content-center'>
                    <div className='col-11 col-md-10'>
                        <div className='mainContent mt-1'>If your ongoing project demands additional resources, new technology or domain specific expertise, then a dedicated team managed by you is an
                            ideal solution.</div>
                        <div className='mainContent mt-2'>Noveline Technologies’ Extended Team model provides the speed, professional acumen and adaptability that your team needs to scale quickly
                            and deliver your products on time and always with the best quality.</div>

                    </div>
                    <div className='col-10 col-md-2 mt-2 img-border text-right'><img className='py-3 flex-row img-fluid' src={Teamicon} style={{ width: "100%" }}></img></div>
                </div>
            </div>
            <div className='container mt-4'>
                <span className='pageMainHeading2 border-btm'>Technologies</span>
                <div className='ml-0'>
                    <table className="table table-responsive table-border mt-2">
                        <thead className="thead-bgcolor">
                            <tr>
                                <th className='text-white pl-4' scope="col">Area</th>
                                <th className='text-white pl-4' scope="col">Skills</th>
                            </tr>
                        </thead>
                        <tbody className='tableborder'>
                            <tr>
                                <td className='table-first-data pl-4'>Front-end</td>
                                <td className='pl-4'>React JS, HTML, CSS, Angular, Windows Forms</td>
                            </tr>
                            <tr>
                                <td className='table-first-data pl-4'>Language / Scripts (including native)</td>
                                <td className='pl-4'>C, C++ , Java, JavaScript, Python, C#, VB.Net</td>
                            </tr>
                            <tr>
                                <td className='table-first-data pl-4'>Framework</td>
                                <td className='pl-4'>Express.js, Bootstrap, .Net</td>
                            </tr>
                            <tr>
                                <td className='table-first-data pl-4'>API</td>
                                <td className='pl-4'>Node.js, Rest API</td>
                            </tr>
                            <tr>
                                <td className='table-first-data pl-4'>Web applications & Services</td>
                                <td className='pl-4'>Asp.net, Asp.net MVC, REST, SOAP, JSON</td>
                            </tr>
                            <tr>
                                <td className='table-first-data pl-4'>Data access</td>
                                <td className='pl-4'>ADO.NET, LINQ, Entity Framework</td>
                            </tr>
                            <tr>
                                <td className='table-first-data pl-4'>Database</td>
                                <td className='pl-4'>MongoDB (Atlas), MySQL(RDS), MSSQL, Oracle</td>
                            </tr>
                            <tr>
                                <td className='table-first-data pl-4'>Webserver</td>
                                <td className='pl-4'>Apache, Nginx, IIS</td>
                            </tr>
                            <tr>
                                <td className='table-first-data pl-4'>Version Control</td>
                                <td className='pl-4'>Git, GitHub, TortoiseSVN, Team Foundation</td>
                            </tr>
                            <tr>
                                <td className='table-first-data pl-4'>IT Infrastructure</td>
                                <td className='pl-4'>AWS</td>
                            </tr>
                            <tr>
                                <td className='table-first-data pl-4'>AI/ML</td>
                                <td className='pl-4'>Linear & Non-linear Optimization</td>
                            </tr>
                            <tr>
                                <td className='table-first-data pl-4'>Mobile App</td>
                                <td className='pl-4'>React Native, Ionic, Android Kotlin</td>
                            </tr>
                            <tr>
                                <td className='table-first-data pl-4'>Security</td>
                                <td className='pl-4'>HTTPS/SSL, Encryption, Auditing, Logging</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>







        // <div className="container-fluid pt-5 pb-5 px-0">
        //     <BreadCrumb text="Solutions " />
        //     < div className='container mt-4'>

        //         <h5 id='first-heading'><span className='heading mt-4' >END-TO-END SOFTWARE OUTSOURCING SOLUTIONS</span></h5>
        //         <p className='mt-4'>We provide companies with access to expert developers boasting a range of tech skills from developers to UX designers. And they can start working on a project right away.</p>
        //         <p>From customization of existing platforms to full-cycle custom software development, Noveline is an experienced development partner to deliver reliable and cost-effective custom software solutions.</p>
        //         <h5 className='mt-4'><span className='heading' >Engagement Models</span></h5>

        //         <div className='container'>
        //             <div className='row mt-4 justify-content-center'>
        //                 <div className='col-auto col-lg-4'>
        //                     <div className='row justify-content-center h-100'>
        //                         <div className='col-11 heading1 font-weight-bold p-2'>
        //                             <span className=' mt-2 '>Team Augmentation</span>
        //                         </div>
        //                         <div className='col-11 border border-danger p-2'>
        //                             Our software engineers work together with your internal team on your projects. These developers join your existing staff, often working from your offices until project completion.
        //                         </div>
        //                     </div>
        //                 </div>
        //                 <div className='col-auto col-lg-4'>
        //                     <div className='row h-100 justify-content-center'>
        //                         <div className='col-11 heading1 font-weight-bold p-2'>
        //                             <span className=' mt-2 '>Delivery team</span>
        //                         </div>
        //                         <div className='col-11 border border-danger p-2'>
        //                             Provides resources which directly oversees the whole development project. This option provides a management structure with hands-on tracking and monitoring of the tasks assigned.
        //                         </div>
        //                     </div>

        //                 </div>
        //                 <div className='col-auto col-lg-4'>
        //                     <div className='row h-100 justify-content-center'>
        //                         <div className='col-11 heading1 font-weight-bold p-2'>
        //                             <span className=' mt-2 '>Project-based</span>
        //                         </div>
        //                         <div className='col-11 border border-danger p-2'>
        //                             Noveline Technologies overseas the entire development process: from establishing the specs, to allocating workflow, setting timescales and quality testing.
        //                         </div>
        //                     </div>

        //                 </div>

        //             </div>
        //         </div>
        //         <div className='mt-4 container'>
        //             <p>
        //                 A dedicated development centre with Noveline Technologies is an engagement model built around productivity optimization, exclusivity of teams, integrity and long term outsourcing relationships.
        //             </p>
        //         </div>

        //         <div className='container  justify-content-center '>
        //             <h5 className='mt-4'><span className='heading' >Noveline Managed</span></h5>
        //             <div className='d-flex justify-content-end  novelinemanage-container'>
        //                 <div>
        //                     <p>We offer full-fledged and efficiently managed dedicated teams for complete coverage of all your technology driven needs. You can determine the degree of monitoring and involvement that is required and we will ensure transparency of a high order in your project.</p>
        //                     <p>An expert autonomous team, typically composed of a Project Manager, Software engineers, QA engineers and other roles, capable of rapidly and efficiently deliver end-to-end technology solutions. Management is conducted jointly by Project Manager and Client's Product Owner. The whole team interacts as often as needed in order to ensure optimal results at delivery time.</p>
        //                 </div>
        //                 <div className=' text-center'>
        //                     <img className='img-border flex-row m-2 margin-left ' src={NovelineManaged}></img>
        //                 </div>
        //             </div>

        //             <h5 className='mt-4'><span className='heading' >Client Managed</span></h5>
        //             <div className='d-flex justify-content-end novelinemanage-container'>
        //                 <div>
        //                     <p>If your ongoing project demands additional resources, new technology or domain specific expertise, then a dedicated team managed by you is an ideal solution.</p>


        //                     <p>Noveline Technologies’ Extended Team model provides the speed, professional acumen and adaptability that your team needs to scale quickly and deliver your products on time and always with the best quality.</p>
        //                 </div>

        //                 <img className='img-border p-2' img-border src={Teamicon} alt="team icon"></img></div>

        //         </div>
        //         <h5 className='mt-4'><span className='heading' >Technologies</span></h5>
        //         <div className='container ml-0 table-container'>
        //             <table className="table table-bordered mt-2 table-left">
        //                 <thead className="thead-bgcolor">
        //                     <tr >

        //                         <th className='text-white pl-4' scope="col">Area</th>
        //                         <th className='text-white pl-4' scope="col">Skills</th>

        //                     </tr>
        //                 </thead>
        //                 <tbody className='tableborder'>
        //                     <tr>
        //                         <td className='table-first-data pl-4'>Front-end</td>
        //                         <td className='pl-4'>React JS, HTML, CSS, Angular, Windows Forms</td>
        //                     </tr>
        //                     <tr>
        //                         <td className='table-first-data pl-4'>Language / Scripts (including native)</td>
        //                         <td className='pl-4'>C, C++ , Java, JavaScript, Python, C#, VB.Net</td>
        //                     </tr>
        //                     <tr>
        //                         <td className='table-first-data pl-4'>Framework</td>
        //                         <td className='pl-4'>Express.js, Bootstrap, .Net</td>
        //                     </tr>
        //                     <tr>
        //                         <td className='table-first-data pl-4'>API</td>
        //                         <td className='pl-4'>Node.js, Rest API</td>
        //                     </tr>
        //                     <tr>
        //                         <td className='table-first-data pl-4'>Web applications & Services</td>
        //                         <td className='pl-4'>Asp.net, Asp.net MVC, REST, SOAP, JSON</td>
        //                     </tr>
        //                     <tr>
        //                         <td className='table-first-data pl-4'>Data access</td>
        //                         <td className='pl-4'>ADO.NET, LINQ, Entity Framework</td>
        //                     </tr>
        //                     <tr>
        //                         <td className='table-first-data pl-4'>Database</td>
        //                         <td className='pl-4'>MongoDB (Atlas), MySQL(RDS), MSSQL, Oracle</td>
        //                     </tr>
        //                     <tr>
        //                         <td className='table-first-data pl-4'>Webserver</td>
        //                         <td className='pl-4'>Apache, Nginx, IIS</td>
        //                     </tr>
        //                     <tr>
        //                         <td className='table-first-data pl-4'>Version Control</td>
        //                         <td className='pl-4'>Git, GitHub, TortoiseSVN, Team Foundation</td>
        //                     </tr>
        //                     <tr>
        //                         <td className='table-first-data pl-4'>IT Infrastructure</td>
        //                         <td className='pl-4'>AWS</td>
        //                     </tr>
        //                     <tr>
        //                         <td className='table-first-data pl-4'>AI/ML</td>
        //                         <td className='pl-4'>Linear & Non-linear Optimization</td>
        //                     </tr>
        //                     <tr>
        //                         <td className='table-first-data pl-4'>Mobile App</td>
        //                         <td className='pl-4'>React Native, Ionic, Android Kotlin</td>
        //                     </tr>
        //                     <tr>
        //                         <td className='table-first-data pl-4'>Security</td>
        //                         <td className='pl-4'>HTTPS/SSL, Encryption, Auditing, Logging</td>
        //                     </tr>
        //                 </tbody>
        //             </table>
        //         </div>
        //     </div>
        // </div >

    )
}

export default OutsourcingSolutionModule;