import React from 'react'
import BreadCrumb from '../BreadCrumb/BreadCrumb'
import Brass from '../../assets/images/BrassRecordsManagement.png'
import Linked from '../../assets/images/brass_record_management_screen-2.png'
import Metadata from '../../assets/images/brass_record_management_screen-1.png'
import Metadata1 from '../../assets/images/brass_record_management_screen1.png'
import View from '../../assets/images/brass_record_management_screen-3.png'
import QSearch from '../../assets/images/brass_record_management_screen-4.png'
import ASearch from '../../assets/images/brass_record_management_screen-5.png'
import Quick from '../../assets/images/brass_record_management_screen-6.png'
import Global from '../../assets/images/brass_record_management_screen-7.png'
import './BrassStyle.scss'
const BrassModule = () => {
    return (
        <>
            <div className="container-fluid mt-4 mt-sm-4 mt-md-2 mt-lg-0  pt-5 pb-5 px-0 ">{/*case-text*/}
                <BreadCrumb text="Case Studies" />
                <div className='container mt-sm-4 mt-4'>
                    <div className='margin-top'>{/*external css margin-top for responsive*/}
                    <span className='pageMainHeading border-btm  mx-0 px-0'>BRASS - RECORDS MANANGEMENT</span>
                    </div>
                </div>
                <div className='container mt-4'>
                    <div className='row mx-0 px-0 blueBg'>
                        <div className='col-12 col-lg-2 text-center'>
                            <div className='mt-3'>
                                <img src={Brass} className="bg border-right-white my-4 img-fluid" style={{ width: "70%" }} alt="" />
                            </div>
                        </div>
                        <div className='col-12 col-lg-10 border-white p-4 p-lg-3 margin'>
                            <div className='aboutHeading'>About the Client</div>
                            <div className='aboutContent mt-1 pe-5'>
                            A Defence organization, conducting round-the-year real-life operations at sea. Despite being relatively small, it has a wide range of task capabilities for both surface and air operations. The organization is headed by the DGICG exercising his overall command and superintendence from the headquarters, CGHQ located at New Delhi. At CGHQ, he is assisted by four Deputy Director Generals of the rank of Inspector General, and other senior officers heading various staff divisions.
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container mt-4'>
                    <span className='pageMainHeading border-btm mx-0 px-0'>Business Requirements</span>
                    <div className='mainContent'>Record - Each record has an associated document, owner, classification and metadata with it. </div>
                </div>
                <div className='container mt-4'>
                    <div className='mainContentHeadingRed'>Records Accessibility</div>
                    <div className='mainContent'>All the records in the repository may belong to different units of the organisation. Any unit will be allowed to view records belonging to their organisation unit. In addition, they will be also allowed to have 'view only' access of documents of the central units. </div>
                </div>
                <div className='container mt-4'>
                    <div className='mainContentHeadingRed'>Connectivity Issue in 'Ships'</div>
                    <div className='mainContent'>The Units that were in 'Ships' were mostly at the sea where intranet connection was not a possibility. In this period, various versions of the records would be uploaded and when the ship arrives on the Port where connectivity is available the repository had to be incrementally updated till the latest version.  </div>
                </div>
                <div className='container mt-4'>
                    <div className='mainContentHeadingRed'>Grouping of Documents</div>
                    <div className='mainContent'>All the records will be grouped in multiple categories. User can drill down to parent and child group at ease. A file can belong to multiple groups. By selecting the group of choice user can view list of files belongs to selected group.  </div>
                </div>
                <div className='container mt-4'>
                    <div className='mainContentHeadingRed'>Global Filters</div>
                    <div className='mainContent'>All the records in the repository may belong to certain set of criteria. Global filters section allows user to view only those documents that meet a set of criteria. The filter that is applied will be applicable for all actions until it is replaced, unselected or removed. </div>
                </div>
                <div className='container mt-4'>
                    <div className='mainContentHeadingRed'>Linked Records</div>
                    <div className='mainContent'>A record may be linked to other records in the repository. Linked records section allows user to view records linked to the currently viewed record.  </div>
                </div>
                <div className='container mt-4'>
                    <div className='mainContentHeadingRed'>Versioning</div>
                    <div className='mainContent'>Whenever, the record stored in centralized repository gets updated, a new version based on Type of version (global / major / minor / trivial) will be created. Multiple versions of the record can be stored without changing the original record, though only the latest version will be visible in the repository.</div>
                </div>
                <div className='container mt-4'>
                    <span className='heading font-bold'>Noveline Solution</span>
                    <div className='container mt-4'>
                        <div className='row'>
                            <div className='col-12 col-lg-6 px-0'>
                                <div className=" bg-red font-bold p-3 text-center">Record Repository</div>
                                <img src={Metadata1} className="mt-3 bg-white img-fluid" alt="Linked" />
                            </div>
                            <div className='col-12 border-left-brass col-lg-6 px-0'>
                                <div className="bg-red font-bold p-3 text-center">Metadata</div>
                                <img src={Metadata} className="mt-3 bg-white img-fluid" alt="Linked" />

                            </div>

                        </div>
                        <div className='row mt-4'>
                            <div className='col-12 col-lg-6 px-0'>
                                <div className=" bg-red font-bold p-3 text-center">Linked Records</div>
                                <img src={Linked} className="mt-3 bg-white img-fluid" alt="Linked" />
                            </div>
                            <div className='col-12 border-left-brass col-lg-6 px-0'>
                                <div className="bg-red font-bold p-3 text-center">View Records</div>
                                <img src={View} className="mt-3 bg-white img-fluid" alt="Linked" />

                            </div>

                        </div>
                        <div className='row mt-4'>
                            <div className='col-12 col-lg-6 px-0'>
                                <div className=" bg-red font-bold p-3 text-center">Quick Search</div>
                                <img src={QSearch} className="mt-3 bg-white img-fluid" alt="Linked" />
                            </div>
                            <div className='col-12 border-left-brass col-lg-6 px-0'>
                                <div className="bg-red font-bold  p-3 text-center">Advanced Search</div>
                                <img src={ASearch} className="mt-3 bg-white img-fluid" alt="Linked" />

                            </div>

                        </div>
                        <div className='row mt-4'>
                            <div className='col-12 col-lg-6 px-0'>
                                <div className=" bg-red font-bold p-3 text-center">Quick View</div>
                                <img src={Quick} className="mt-3 bg-white img-fluid" alt="Linked" />
                            </div>
                            <div className='col-12 border-left-brass col-lg-6 px-0'>
                                <div className="bg-red font-bold p-3 text-center">Global Filters</div>
                                <img src={Global} className="mt-3 bg-white img-fluid" alt="Linked" />

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BrassModule