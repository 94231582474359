import React from 'react'
import Header from '../modules/HeaderModule/Header'
import Footer from '../modules/FooterModule/Footer'
import OutsourcingSolutionModule from '../modules/SolutionsPagesModule/OutsourcingSolutionModule'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const OutsourcingPage = () => {
  const location = useLocation();


  useEffect(() => {
    document.title = 'Noveline - Outsourcing';
  }, []);

  return (
    <>
      <Header activeName={location.pathname} />
      <OutsourcingSolutionModule />
      <Footer scroll_position={100} />

    </>
  )
}

export default OutsourcingPage