import Carousel from 'react-bootstrap/Carousel';
import logo from '../../assets/images/NoelineLogoWhiteBg.png'
// import logo from '../../assets/images/NoelineLogoBack.png'
const CarouselFade = () => {
    return (
        <>
            <Carousel fade={true} controls={false} indicators={false} pause={false} >
                <Carousel.Item interval={7000} className='my-5'>
                    <div className="row justify-content-start">
                        <div className="col-12 col-md-8 homeText1 pt-3 pt-md-5 logoAni">
                            <img src={logo} alt="NovelineLogo" width={"75%"}
                                className="d-inline-block align-text-top imgAni" />
                          
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item interval={15000} className='my-5'>
                    <div className="row">
                        <div className="col-12 homeText1 pt-3 pt-md-5 text-left b1">
                            TECHNOLOGY
                        </div>
                        <div className="col-12">
                            <span className="conjunctionText">that</span>
                            <span className="homeText2 box2 mx-1 mx-md-2 b2">
                                solves problems
                            </span>
                            <span className="homeText2 box2 mx-1 mx-md-2 b3">saves cost</span>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item interval={15000} className='my-5'>
                    <div className="row">
                        <div className="col-12 homeText1 pt-3 pt-md-5 text-left b1">
                            SOLUTIONS
                        </div>
                        <div className="col-12">
                            <span className="conjunctionText">that</span>
                            <span className="homeText2 box2 mx-md-1 mx-0 mx-md-2 b2">
                                pushes boundaries
                            </span>
                            <span className="homeText2 box2 b3 mx-1 mx-md-2 ">ensures outcomes</span>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item interval={15000} className='my-5'>
                    <div className="row">
                        <div className="col-12 homeText1 pt-3 pt-md-5 text-left b1">
                            PROFESSIONALS
                        </div>
                        <div className="col-12">
                            <span className="conjunctionText">who</span><span className="homeText2 box2 mx-1 mx-md-2 b2">
                                think out of the box
                            </span>
                            <span className="homeText2 box2 b3 mx-1 mx-md-2">are ahead of the curve</span>
                        </div>
                    </div>
                </Carousel.Item>
            </Carousel>
        </>
    );
}

export default CarouselFade;