import React from 'react'
import img1 from '../assets/images/wheelImg.png'
// import img1 from '../../assets/images/wheelImg.png';
import img2 from '../assets/images/wheelImg2.png';
import '../App.scss'
const Errorpage = () => {
  return (
    <div className='mt-5 text-center'>
      <h1>
        <span className='errorpage404'>404</span> </h1>
      <h2 className='errorpagenotfound'>Not Found</h2>
      <div className="text-left container w-25 mt-3 pb-5 WheelContainer">
        <img src={img1} className="WheelclockwiseSpin" width="200" height="230" alt='WheelclockwiseSpin' />
        <div className="text-end wheel2">
          <img src={img2} className="WheelantiClockwiseSpin" width="150" height="150" alt="WheelantiClockwiseSpin" />
        </div>
      </div>
    </div>
  )
}

export default Errorpage